import React, { Component, createRef  } from 'react';
import './css/ListChatSupportUser.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import Firebase from '../../components/Firebase.js';
import lodash from 'lodash'
import { Base64 } from 'js-base64';
import MyContext from '../../common/MyContext';
import MessageList from './components/MessageList.js';
import MessageDetailList from './components/MessageDetailList.js';
import ReactScroll from 'react-scrollable-feed';
import './css/ListChatSupportUser.css';

export default class ListChatSupportUser extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const user = {
        name: 'Yokara Official', 
        facebookId: '105912617350916906040', 
        profileImageLink: 'https://www.ikara.co/avatar/122675726976908', 
        url: null,
        totalIcoin: 0,
        uid: 4505482,
        vipOkara: false,
        userId: null
    };
    this.state = {
        user: user,
        targetUser: null,
        roomId: "",
        messages: [],
        cursorMessages: null,
        messageDetails: [],
        searchUsers: [],
        cursorMessageDetails: null,
        loaded: false,
        isFirst: false,
        loading: false,
        listenFirebaseMessages: null,
        listenFirebaseMessgeDetails: null,
        message: "",
        searchKeyword: "",
        isUserActive: false,
        queryType: "ALL" //ALL, UNREAD
    }
    this.ikaraNode = Firebase.database().ref("ikara");
    this.functions = Firebase.functions();
    this.auth = Firebase.auth();
    this.getFirebaseToken = this.getFirebaseToken.bind(this);
    this.accessToken = this.accessToken.bind(this);
    this.listenFirebaseMessages = this.listenFirebaseMessages.bind(this);
    this.listenFirebaseMessgeDetails = this.listenFirebaseMessgeDetails.bind(this);
    this.getMessageDetail = this.getMessageDetail.bind(this);
    this.loadMoreMessageDetails = this.loadMoreMessageDetails.bind(this);
    this.offListen = this.offListen.bind(this);
    this.handleTargetUser = this.handleTargetUser.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.searchUsers = this.searchUsers.bind(this)
    this.uploadImage = this.uploadImage.bind(this);
    this.acceptSendVideo = this.acceptSendVideo.bind(this);
    this.setQueryType = this.setQueryType.bind(this);
    this.chatContainerRef = createRef();
    this.messagesBoxRef = createRef();
    this.currentTimeout = null;
  }

  componentDidMount() {
    this.getFirebaseToken();
  }

  setQueryType(queryType) {
    this.setState({
        queryType: queryType,
        targetUser: null,
        messages: [],
        messageDetails: [],
        cursorMessageDetails: null,
        message: "",
        searchKeyword: "",
    }, () => {
        this.listenFirebaseMessages();
    })
  }

  handleScroll() {
    const { loading, loaded } = this.state;
    if ($('.chat-box').scrollTop() < 150 && !loading && loaded) {
        this.state.loading = true;
        console.log("loadmore")
        this.loadMoreMessageDetails()
    }
    console.log($('.chat-box').scrollTop())
  }

  getFirebaseToken() {
    let { user } = this.state;
    axios.post(global.config.apiDomain + "/rest/admin/getFirebaseToken", user.facebookId, {
        headers : {
            "Content-Type": "application/json"
        }
    })
        .then(res => {
            if (res.data != null) {
                this.setState({ user: res.data.user }, () => {
                    this.accessToken();
                });
            }
        });
  }

  acceptSendVideo() {
    let acceptSendVideo = false;
    if (this.state.messageDetails && this.state.messageDetails.length > 1) {
        let firstUser = this.state.messageDetails[0].userId;
        this.state.messageDetails.map(value => {
            if (value.userId && value.userId !== firstUser) {
                acceptSendVideo = true;
            }
    })
    }
    return acceptSendVideo;
  }

  accessToken() {
    const { user } = this.state;
    this.auth.signInWithCustomToken(user.firebaseToken)
        .then(res => {
            this.listenFirebaseMessages();
        }).catch(error => {
            console.error("Error signing in with custom token:", error)});
  }

  uploadImage = (event) => {
    if(window.confirm("Bạn có chắc muốn gửi file này ?")) {
        var target = event.target;
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }
        this.setState({
            loading: true
        });
        var bucketName = "ikara-data/chat";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "mp4") {
            contentType = "video/mp4";
        }
        if (extension == "mov") {
            contentType = "video/mov";
        }
        if(extension !== "jpeg" && extension !== "jpg" && extension !== "png" && extension !== "mp4" && extension !== "mov") {
            toast.warn("Không đúng định dạng")
            this.setState({
                loading: false
            });
            return;
        }
        if ((extension === "mp4" || extension === "mov") && !this.acceptSendVideo()) {
            toast.warn("Không thể gửi video do chưa xác lập hội thoại")
            this.setState({
                loading: false
            });
            return;
        }
        var fileReader = new FileReader();
        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile ',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false,
                        message: '["https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName + '"]'
                    });
                    if (extension === "mp4") {
                        this.sendMessage("video");
                    } else {
                        this.sendMessage("image");
                    }
                }.bind(this),error: function(jqXHR, exception) {
                    toast.error(exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }
  }

  guid() {
    function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

    getMessageDetail() {
        const { user, targetUser } = this.state;
        var roomId = "";
        if (user.facebookId > targetUser.fromUserId) {
            roomId = targetUser.fromUserId + "-" + user.facebookId;
        } else {
            roomId = user.facebookId + "-" + targetUser.fromUserId;
        }
        this.state.roomId = roomId;
        this.ikaraNode
            .child("privateChats")
            .child(roomId)
            .orderByKey()
            .limitToLast(50)
            .get()
            .then(snapshot => {
                var messageDetails = [];
                snapshot.forEach(value => {
                    var chatItem = value.val();
                    chatItem.key = value.key;
                    if (!this.state.messageDetails.some(o => o.key == chatItem.key)
                        && chatItem.key != "pushNotification"
                        && chatItem.key != "readLimit"
                        && chatItem.key != "properties") {
                        if(chatItem.type != null && !(chatItem.type == "text" || chatItem.type == "image")) {
                            messageDetails.originalMessage = "Chưa hỗ trợ xem data";
                        }
                        messageDetails.push(chatItem);
                    }
                })
                if (messageDetails.length < 9) {
                    this.state.cursorMessageDetails = "END";
                } else if (messageDetails.length > 0) {
                    this.state.cursorMessageDetails = messageDetails[0].key;
                }
                this.state.messageDetails = messageDetails;
                this.setState({
                loaded: true
                }, () => {
                    this.scrollToBottom();
                });
            });
        this.listenFirebaseMessgeDetails();
    }

    loadMoreMessageDetails() {
        const { roomId } = this.state;
        let { cursorMessageDetails } = this.state;
        console.log(cursorMessageDetails);

        if (cursorMessageDetails == "END") return;
        this.ikaraNode
            .child("privateChats")
            .child(roomId)
            .orderByKey()
            .endAt(cursorMessageDetails)
            .limitToLast(10)
            .get()
            .then(snapshot => {
            var messageDetails = [];
            snapshot.forEach(value => {
                var chatItem = value.val();
                chatItem.key = value.key;
                if (!this.state.messageDetails.some(o => o.key == chatItem.key)) {
                    messageDetails.push(chatItem);
                }
            })
            if (messageDetails.length < 9) {
                this.state.cursorMessageDetails = "END";
            } else if (messageDetails.length > 0) {
                this.state.cursorMessageDetails = messageDetails[0].key;
            }
            this.setState({
                messageDetails: messageDetails.concat(this.state.messageDetails),
            });
            this.state.loading = false;
        });
    }
    
    listenFirebaseMessages() {
        this.offListen();

        const { user, queryType } = this.state;
        let { listenFirebaseMessages } = this.state;
        listenFirebaseMessages = this.ikaraNode
            .child("users")
            .child(user.facebookId)
            .child("privateChats");
        
        if(queryType === "UNREAD") {
            listenFirebaseMessages = listenFirebaseMessages.orderByChild("isRead").equalTo(false).limitToLast(100);
        } else {
            listenFirebaseMessages = listenFirebaseMessages.orderByChild("dateTime").limitToLast(30);
        }

        const recursiveListen = () => {
            listenFirebaseMessages.on('value', snapshot => {
                console.log(snapshot.val())
                let messages = [];
                snapshot.forEach(value => {
                    const message = value.val();
                    message.fromUserId = value.key;
                    messages.push(message);
                });
                messages = lodash.orderBy(messages, "dateTime", 'desc').slice(0, 30);
                this.setState({ messages }, () => {
                    this.scrollToTop();
                    this.runForLoop(messages);
                });
            });
        };
        
        this.setState({ listenFirebaseMessages }, () => {
            recursiveListen();
        });

    }
    
    runForLoop(messages) {
        if(messages && messages.length > 0 && this.state.targetUser == null) {
            this.setState({
                targetUser: messages[0]
            }, () => {
                this.getMessageDetail();
            })
        }

        // let currentIndex = 0;
        // const executeLoop = () => {
        //     if (this.state.isUserActive) {
        //         this.currentTimeout = setTimeout(executeLoop, 5000);
        //         return;
        //     }
    
        //     if (currentIndex >= 1) {
        //         this.offListen();
        //     }
        //     if (currentIndex >= messages.length) {
        //         console.log("runForLoop")
        //         this.listenFirebaseMessages();
        //         return;
        //     }
        //     this.setState({ targetUser: messages[currentIndex] }, () => {
        //         if (currentIndex >= 5) {
        //             this.scrollToBottom1();
        //         }
        //         if (this.state.targetUser.isRead && !this.state.targetUser.isRead) {
        //             this.changeIsReadConversation(this.state.targetUser);
        //         }
        //         this.getMessageDetail();
        //         currentIndex++;
        //         this.currentTimeout = setTimeout(executeLoop, 20000);
        //     });
        // };
        // if (this.currentTimeout) {
        //     clearTimeout(this.currentTimeout);
        // }
        // executeLoop();
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        this.setState({ 
            message: value,
            isUserActive: value.length > 0
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.sendMessage("text");
        }
    }
    
    offListen() {
        const { listenFirebaseMessages, listenFirebaseMessgeDetails } = this.state;
        if(listenFirebaseMessages != null && listenFirebaseMessgeDetails != undefined) {
            listenFirebaseMessages.off();
        }

        if(listenFirebaseMessgeDetails != null && listenFirebaseMessgeDetails != undefined) {
            listenFirebaseMessgeDetails.off();
        }
    }

  listenFirebaseMessgeDetails() {
    const { user, targetUser } = this.state;
    let { listenFirebaseMessgeDetails } = this.state;
    var roomId = "";
    if (user.facebookId > targetUser.fromUserId) {
        roomId = targetUser.fromUserId + "-" + user.facebookId;
    } else {
        roomId = user.facebookId + "-" + targetUser.fromUserId;
    }
    this.state.roomId = roomId;
    listenFirebaseMessgeDetails = this.ikaraNode
        .child("privateChats")
        .child(roomId)
        .orderByChild("dateTime")
        .limitToLast(1);
    listenFirebaseMessgeDetails.on('child_added', snapshot => {
        var {messageDetails} = this.state;
        var chatItem = snapshot.val();
        chatItem.key = chatItem.dateTime;
        console.log(this.state.messageDetails)
        if (!this.state.messageDetails.some(o => o.key == chatItem.key) && chatItem.key != undefined) {
            messageDetails.push(chatItem)
            
            this.setState({
                messageDetails: messageDetails,
              }, () => {
                  this.scrollToBottom();
              });
        }

    });
    this.state.listenFirebaseMessgeDetails = listenFirebaseMessgeDetails;
  }

  handleTargetUser(targetUser) {
    const oldTargetUser = this.state.targetUser;
    if(oldTargetUser != null && targetUser.fromUserId == oldTargetUser.fromUserId) {
        return;
    }
    if (!this.state.messages.some(o => o.fromUserId == targetUser.fromUserId)) {
        this.state.messages.unshift(targetUser);
    }
    this.state.searchKeyword = "";
    this.state.searchUsers = [];
    // if (!targetUser.isRead) {
    //     this.changeIsReadConversation(targetUser);
    // }
    if(oldTargetUser != null) {
        this.offListen();
    }
    this.state.targetUser = targetUser;
    this.getMessageDetail();
  }

  changeIsReadConversation(targetUser) {
    if(this.state.queryType === "UNREAD") return;
    const json = {
        toFacebookId: targetUser.fromUserId
    }
    const jsonBase64 = Base64.encode(JSON.stringify(json));
    const parameters = {
        "parameters": jsonBase64
    }
    const request = this.functions.httpsCallable("v5-ChangeIsReadConversation");
    request(parameters)
        .then(result => {
        }).catch(error => {
            alert(error);
        });
  }

  sendMessage = (type) => {
    const { user, targetUser, message} = this.state;
    if (message == "" || user == null || targetUser == null) {
        return;
    }
    const fromUser = {
        facebookId: user.facebookId,
        name: user.name,
        profileImageLink: user.profileImageLink
    }
    const toUser = {
        facebookId: targetUser.fromUserId,
        name: targetUser.userName,
        profileImageLink: targetUser.userProfile
    }
    const json = {
        fromUser: fromUser,
        toUser: toUser,
        message: message,
        type: type,
        firebaseId: this.ikaraNode.push(json).key
    }        
    const jsonBase64 = Base64.encode(JSON.stringify(json))
    var parameters = {
        "parameters": jsonBase64
    }
    const addMessage = this.functions.httpsCallable("v10-SendMessage");
    addMessage(parameters).then(result => {
    this.setState(prevState => ({
        message: "",
        isUserActive: false,
        messageDetails: [json, ...prevState.messageDetails]
    }), () => {
        // this.runForLoop(this.state.messages);
    });
    }).catch(error => {
    })
}

  searchUsers() {
    const {searchKeyword} = this.state;
    let {user} = this.state;
    let {targetUser} = this.state;
    if(searchKeyword == "") {
        return;
    }
    axios.post(global.config.apiDomain + "/rest/searchUser", searchKeyword, {
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        let messages = [];
        if(res.data != null) {
            for(let i = 0; i< res.data.length; i++) {
                if(user.facebookId === res.data[i].facebookId) continue;
                let message = {
                    fromUserId: res.data[i].facebookId,
                    userProfile : res.data[i].profileImageLink,
                    userName : res.data[i].name,
                    lastMessage: "",
                    dateTime: null
                };
                targetUser= message
                messages.push(message)
            }
            this.handleTargetUser(targetUser)
        }
        this.setState({
            searchUsers: messages
        })
    })
  }

  scrollToBottom = () => {
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
    }
  }

  scrollToBottom1 = () => {
    if (this.messagesBoxRef.current) {
      const currentScrollTop = this.messagesBoxRef.current.scrollTop;
      const newScrollTop = currentScrollTop + 200;
      const maxScrollTop = this.messagesBoxRef.current.scrollHeight - this.messagesBoxRef.current.clientHeight;
      this.messagesBoxRef.current.scrollTop = Math.min(newScrollTop, maxScrollTop);
    }
  }
  
  scrollToTop = () => {
    if (this.messagesBoxRef.current) {
      this.messagesBoxRef.current.scrollTop = 0;
    }
  }

  render() {
    const { user, targetUser, messages, messageDetails, searchUsers, loaded, message, searchKeyword, cursorMessages } = this.state;
    console.log(messageDetails)
    return (
      <div className="list-chat-support-user">
        <div className="friends-list">
          <input
              value={searchKeyword}
              onChange={(event) => {
                  if(event.target.value === "") {
                      this.setState({ searchUsers: [] });
                  }
                  this.setState({ searchKeyword: event.target.value });
              }}
              onKeyPress={(event) => {
                  if(event.key === "Enter") {
                      this.searchUsers();
                  }
              }}
              type="text"
              placeholder="Search User"
              aria-describedby="button-addon2"
              className="form-control rounded-0 border-0 py-4 bg-light"
          />
          <div className='d-flex justify-content-around'>
            <button onClick={() => this.setQueryType("ALL")} className='btn btn-primary'>Tất cả</button>
            <button onClick={() => this.setQueryType("UNREAD")} className='btn btn-primary'>Chưa xem</button>
          </div>
          <div style={{ height: "-webkit-fill-available", overflowY: "auto"}} className="messages-box" ref={this.messagesBoxRef}>
            {loaded && (searchUsers.length === 0 || searchKeyword === "") ? (
                <MessageList 
                    messages={messages}
                    user={user}
                    targetUser={targetUser}
                    handleTargetUser={this.handleTargetUser}
                />
            ) : (
                <MessageList 
                    messages={searchUsers}
                    user={user}
                    targetUser={targetUser}
                    handleTargetUser={this.handleTargetUser}
                />
            )}

            {cursorMessages != null && cursorMessages != "END" && (
                <div className='d-flex justify-content-center'>
                    <button className='btn btn-primary'>Xem thêm</button>
                </div>
            )}

          </div>

        </div>
        <div className="chat-section">
          <div className="form-control rounded-0 border-0 py-4 bg-light" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="chat-header">
            {targetUser ? `${targetUser.userName}` : 'Đang nói chuyện với...'}
          </div>
          </div>
          <div className="chat-container" ref={this.chatContainerRef}>
            <ReactScroll
                className="mt-2 px-4 chat-box bg-white">
                <MessageDetailList
                    messageDetails={messageDetails}
                    user={user}
                    targetUser={targetUser}
                />
            </ReactScroll>
          </div>
          <div className="input-container">
            <input
                onKeyPress={this.handleKeyPress}
                value={message}
                onChange={this.handleInputChange}
                type="text"
                placeholder="Type a message"
                aria-describedby="button-addon2"
                className="form-control rounded-0 border-0 py-4 bg-light"
            />

            <div className="input-group-append">
              <button
                  id="button-addon2"
                  className="btn btn-link"
                  onClick={() => this.sendMessage("text")}
                  type="button"
                  disabled={this.state.loading}
              >
                  {this.state.loading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-paper-plane" />}
              </button>
            </div>

            <div className="input-group-append">
              <label htmlFor="imageAnswer" className="file-input-label">
                  <i className="fa fa-image"></i>
              </label>
              <input
                  type="file"
                  id="imageAnswer"
                  className="custom-file-input"
                  onChange={this.uploadImage}
                  style={{ display: 'none' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListChatSupportUser.contextType = MyContext;