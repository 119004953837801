import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import getImageSize from 'image-size-from-url';
import { Base64 } from 'js-base64';

import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

export default class ModalUpdateRoomEntryEffect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gift: {
                sourceType: '',
                name: '',
                expiry: 0,
                colorCode: '',
                resourceUrl: '',
                thumbnailUrl: '',
                price: 0,
                dateStart: new Date().toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T"),
                dateEnd: new Date().toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T"),
                order: 0
            },
            loading: false
        }
        this.saveGift = this.saveGift.bind(this);
        // this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.uploadAnimationToS4 = this.uploadAnimationToS4.bind(this);
        this.uploadAnimationToS5 = this.uploadAnimationToS5.bind(this);

    }

    componentWillReceiveProps(props) {
        let { gift } = props;

        if (gift != null) {
            gift.dateStart = new Date(gift.dateStart).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
            gift.dateEnd = new Date(gift.dateEnd).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
            if (gift._id == null) {
                this.setState({
                    gift: gift,
                });
                $("#show-animation_resourceUrl").children().replaceWith("<img style=\"height: 0px !important\"  />");
                $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 0px !important\"  />");
                // $("#show-animation").children().replaceWith("<img style=\"height: 0px !important\"  />");
            } else {
                this.setState({
                    gift: gift,
                });

                if (gift.thumbnailUrl != null && gift.thumbnailUrl.endsWith("svga")) {
                    $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                    this.loadSVGA4(gift.thumbnailUrl);
                } else if (gift.thumbnailUrl != null) {
                    $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.thumbnailUrl + "\"  />");
                }

                if (gift.resourceUrl != null && gift.resourceUrl.endsWith("svga")) {
                    $("#show-animation_resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                    this.loadSVGA4(gift.resourceUrl);
                } else if (gift.resourceUrl != null) {
                    $("#show-animation_resourceUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.resourceUrl + "\"  />");
                }

                // if (gift.nameFrameUrl != null && gift.nameFrameUrl.endsWith("svga")) {
                //     $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas2\"></div>");
                //     this.loadSVGA2(gift.nameFrameUrl);
                // } else if (gift.nameFrameUrl != null) {
                //     $("#show-animation").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.nameFrameUrl + "\"  />");
                // }
            }
        }
    }

    loadSVGA4(url) {
        var player = new SVGA.Player('#demoCanvas4');
        var parser = new SVGA.Parser('#demoCanvas4');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    // uploadAnimationToS3(event) {
    //     var target = event.target;
    //     //10495760
    //     // if (target.files[0].size > 1049576 ){
    //     //     toast.error("Kích thước file phải dưới 1mb.");
    //     //     return;
    //     // }

    //     this.setState({
    //         loading: true
    //     });


    //     var gift = this.state.gift;
    //     var bucketName = "ikara-data/images/roomEntryEffect";
    //     var extension = target.files[0].name.split('.').pop().toLowerCase();
    //     var keyName = this.guid() + "." + extension;
    //     var contentType = "image/svga";
    //     if (extension == "png") contentType = "image/svga";

    //     var fileReader = new FileReader();

    //     fileReader.onload = function () {
    //         var fd = new FormData();
    //         fd.append('file', target.files[0]);
    //         fd.append('bucketName', bucketName);
    //         fd.append('objectKey', keyName);
    //         fd.append('contentType', contentType)
    //         $.ajax({
    //             url: global.config.apiDomain + '/web.UploadB2',
    //             data: fd,
    //             processData: false,
    //             contentType: false,
    //             type: 'POST',
    //             success: function (data) {
    //                 console.log(data);
    //                 this.setState({
    //                     loading: false
    //                 });

    //                 gift.nameFrameUrl = 'https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName;
    //                 this.state.gift.nameFrameUrl = 'https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName;
    //                 this.setState({
    //                     gift: gift
    //                 });


    //                 if (gift.nameFrameUrl != null && gift.nameFrameUrl.endsWith("svga")) {
    //                     $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
    //                     this.loadSVGA4(gift.nameFrameUrl);
    //                 } else if (gift.nameFrameUrl != null) {
    //                     $("#show-animation").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.nameFrameUrl + "\"  />");
    //                 }
    //             }.bind(this),
    //         });
    //     }.bind(this);

    //     fileReader.readAsArrayBuffer(target.files[0]);

    // }

    uploadAnimationToS4(event) {
        var target = event.target;
        //10495760
        // if (target.files[0].size > 1049576 ){
        //     toast.error("Kích thước file phải dưới 1mb.");
        //     return;
        // }

          //05Dec24 Check if the file size exceeds 1MB
    if (  target.files[0].size > 1048576) { // 1MB in bytes
        toast.error("Hình ảnh không được vượt quá dung lượng 1mb ");
        return;
    }
        this.setState({
            loading: true
        });


        var gift = this.state.gift;
        var bucketName = "ikara-data/images/roomEntryEffect";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    gift.resourceUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.gift.resourceUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        gift: gift
                    });


                    if (gift.resourceUrl != null && gift.resourceUrl.endsWith("svga")) {
                        $("#show-animation_resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(gift.resourceUrl);
                    } else if (gift.resourceUrl != null) {
                        $("#show-animation_resourceUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.resourceUrl + "\"  />");
                    }
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadAnimationToS5(event) {
        var target = event.target;
        //10495760
        // if (target.files[0].size > 1049576 ){
        //     toast.error("Kích thước file phải dưới 1mb.");
        //     return;
        // }
        //05Dec24 Check if the file size exceeds 1MB
        if (  target.files[0].size > 1048576) { // 1MB in bytes
            toast.error("Hình ảnh không được vượt quá dung lượng 1mb ");
            return;
        }
        this.setState({
            loading: true
        });


        var gift = this.state.gift;
        var bucketName = "ikara-data/images/roomEntryEffect";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    gift.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.gift.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        gift: gift
                    });


                    if (gift.thumbnailUrl != null && gift.thumbnailUrl.endsWith("svga")) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(gift.thumbnailUrl);
                    } else if (gift.thumbnailUrl != null) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.thumbnailUrl + "\"  />");
                    }
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    saveGift() {
        let gift = {...this.state.gift};
        if (gift == null ||
            gift.sourceType == null ||
            gift.name == null ||
            gift.expiry == null ||
            // gift.colorCode == null ||
            // gift.nameFrameUrl == null ||
            // gift.resourceUrl == null ||
            gift.price == null ||
            gift.dateStart == null ||
            gift.dateEnd == null ||
            gift.order == null
        ) {
            toast.error("Vui lòng điền đầy đủ thông tin.");
            return;
        }

        gift.dateStart = Date.parse(gift.dateStart);
        gift.dateEnd = Date.parse(gift.dateEnd);

        if (gift.vipRequire == null) gift.vipRequire = 0;
        if (gift.lvRequire == null) gift.lvRequire = 0;
        gift.autoRenew = true;
        gift.vipDiscount = 0.9;
        gift.type = "RoomEntryEffect";
        console.log(gift);
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/shop-items/createOrUpdateShopItem', gift)
            .then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalUpdateRoomEntryEffect").modal("hide");
                this.props.resetList(true);
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
            toast.error(error);
        })
    }

    render() {
        const model = bindModel(this);
        const { gift } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateRoomEntryEffect" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật hiệu ứng vào phòng</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {true ? (
                                <div className="modal-body">
                                    <div id="order" className="row mb-2">
                                        <div className="col-3">
                                            <label>Độ ưu tiên hiển thị</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="order"
                                                placeholder="Ưu tiên hiển thị thứ tự lớn hơn"
                                                {...model('gift.order')} />
                                        </div>
                                    </div>
                                    <div id="sourceType" className="row mb-2">
                                        <div className="col-3">
                                            <label>Thể loại Vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="sourceType"
                                                {...model('gift.sourceType')} >
                                                <option value="">Chọn</option>
                                                <option value="NORMAL">Bán trong Cửa hàng</option>
                                                <option value="SYSTEM">Hệ thống</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="_id" className="row mb-2">
                                        <div className="col-3">
                                            <label>ID Vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="platform"
                                                readOnly={true}
                                                placeholder="ID sẽ được tạo tự động"
                                                {...model('gift._id')} />
                                        </div>
                                    </div>
                                    <div id="name" className="row mb-2">
                                        <div className="col-3">
                                            <label>Tên vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="platform"
                                                placeholder="Nhập tên VP"
                                                {...model('gift.name')} />
                                        </div>
                                    </div>
                                    <div id="expiry" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hạn sử dụng</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.buyPrice"
                                                defaultValue='7'
                                                placeholder="Nhập số ngày hết hạn"
                                                {...model('gift.expiry')} />
                                        </div>
                                    </div>
                                    {/* <div id="nameFrameUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Khung tên</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile03"
                                                accept=".png"
                                                onChange={this.uploadAnimationToS3}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                        </div>
                                    </div> */}
                                    {/* <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div> */}
                                    <div id="resourceUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hiệu ứng</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile04"
                                                onChange={this.uploadAnimationToS4}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile04">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_resourceUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hình ảnh mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile05"
                                                onChange={this.uploadAnimationToS5}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile05">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="price" className="row mb-2">
                                        <div className="col-3">
                                            <label>Giá vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="title"
                                                placeholder="Nhập giá của vật phẩm"
                                                {...model('gift.price')} />
                                        </div>
                                    </div>
                                    <div id="dateStart" className="row mb-2">
                                        <div className="col-3">
                                            <label>Ngày mở bán</label>
                                        </div>
                                        <div className="col-9">
                                        <input
                                                className="form-control mb-2"
                                                type="datetime-local"
                                                name="dateStart"
                                                {...model('gift.dateStart')}
                                            />
                                        </div>
                                    </div>
                                    <div id="dateEnd" className="row mb-2">
                                    <div className="col-3">
                                    <label>Ngày kết thúc</label>
                                </div>
                                <div className="col-9">
                                    <select
                                        className="form-control"
                                        name="gift.dateEnd"
                                        onChange={(e) => {
                                            const selectedDate = e.target.value;
                                            console.log("Selected date:", selectedDate);
                                            let dateEndValue;
                                            switch (selectedDate) {
                                                case "infinity":
                                                    dateEndValue = "9999-12-31";
                                                    break;
                                                default:
                                                    dateEndValue = selectedDate;
                                                    break;
                                            }
                                            this.setState(prevState => ({
                                                gift: {
                                                    ...prevState.gift,
                                                    dateEnd: dateEndValue
                                                }
                                            }));
                                        }}
                                        value={gift.dateEnd === "9999-12-31" ? "9999-12-31" : ""}>
                                        <option value="">Chọn ngày kết thúc...</option>
                                        <option value="9999-12-31">Vô hạn</option>
                                    </select>

                                    {gift.dateEnd === "9999-12-31" ? (
                                        <p>Vô hạn</p>
                                    ) : (
                                        <input
                                            className="form-control mt-2"
                                            type="date"
                                            value={gift.dateEnd}
                                            onChange={(e) => {
                                                const selectedDate = new Date(e.target.value);
                                                console.log("Selected specific date:", selectedDate.toISOString().slice(0, 10));
                                                this.setState(prevState => ({
                                                    gift: {
                                                        ...prevState.gift,
                                                        dateEnd: selectedDate.toISOString().slice(0, 10)
                                                    }
                                                }));
                                            }}
                                        />
                                    )}
                                </div>
                                </div>
                                    <div id="lvRequire" className="row mb-2">
                                        <div className="col-3">
                                            <label>LV yêu cầu</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="title"
                                                placeholder="Nhập lv có thể mua"
                                                {...model('gift.lvRequire')} />
                                        </div>
                                    </div>
                                    <div id="vipRequire" className="row mb-2">
                                        <div className="col-3">
                                            <label>Vip yêu cầu</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="title"
                                                placeholder="Nhập cấp vip được mua"
                                                {...model('gift.vipRequire')} />
                                        </div>
                                    </div>
                                    <div id="idAllowed" className="row mb-2">
                                        <div className="col-3">
                                            <label>ID được hiển thị</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="title"
                                                placeholder="Nhập id được hiển thị"
                                                {...model('gift.idAllowed')} />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <textarea rows="4"
                                                className="form-control mb-2"
                                                placeholder="Nhập mô tả"
                                                {...model('gift.description')} />
                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal" >Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveGift}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}