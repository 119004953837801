import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";

import axios from 'axios';
import ModalPaymentHistory from '../AccountManagerPage/components/ModalPaymentHistory.js';

export default function PartnerIcoinDetail() {
    const { uid } = useParams(); // Extract :uid from the URL
    const query = new URLSearchParams(useLocation().search);
    const label = query.get("label");
    const [list, setList] = useState([]);
    const [icoinPlusIdSeleted, setIcoinPlusIdSeleted] = useState([]);

    useEffect(() => {
        getPartnerIcoinDetail()
        
    }, []);

    const getPartnerIcoinDetail = () => {
        console.log('getPartnerIcoinDetail');
        let date = parseDate(label);
        
        axios.post(
                global.config.apiDomain + '/rest/partner-duration/web/get-partner-icoin-detail',
                { date: date.getTime(), uid: +uid },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then(res => {
                setList(res.data.data.list);
            })
            .catch(error => {
                console.error('Error fetching commissions:', error);
            });
    };

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');  
        return new Date(Number(year), Number(month) - 1, Number(day), 7, 0); 
      }

    return (
        <>
            <ModalPaymentHistory icoinPlusId={icoinPlusIdSeleted}></ModalPaymentHistory>
            <div id="content">
                <div className="container">
                    <h1 className="text-center">Chi tiết iCoin CTV <span className="text-primary">{uid}</span> Ngày <span className="text-warning">{label}</span></h1>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Ngày Tháng</th>
                                <th scope="col">Hành Động</th>
                                <th scope="col">Trạng thái</th>
                                <th scope="col">Số Dư Trước GD</th>
                                <th scope="col">Giá Trị</th>
                                <th scope="col">Số Dư Sau GD</th>
                                <th scope="col">Chi tiết</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list ? (
                                list.map((payment, index) => {
                                    return (<tr key={index}>
                                        <td>{payment.id}</td>
                                        <td>{new Date(payment.addTime).toLocaleString()}</td>
                                        <td>{payment.type}</td>
                                        <td>{payment.status}</td>
                                        <td>{payment.oldIcoin}</td>
                                        <td>{payment.newIcoin}</td>
                                        <td>{payment.totalIcoin}</td>
                                        <td><button
                                            onClick={e => setIcoinPlusIdSeleted(payment.id)}
                                            data-toggle="modal"
                                            data-target="#modalPaymentHistory"
                                            className="btn btn-outline-primary"><i className="fa fa-eye"></i></button></td>
                                    </tr>)
                                })
                            ) : <></>}

                        </tbody>
                    </table>
                
                </div>
            </div>
        </>
    );
    
}