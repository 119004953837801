import React, { Component } from 'react';
import bindModel from '../../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Link } from "react-router-dom";

export default class DetailRecordingVipPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recording: null,
            loading: false
        }

        this.getRecordingVip = this.getRecordingVip.bind(this);
        this.saveRecordingVip = this.saveRecordingVip.bind(this);
        this.revertRecordingVip = this.revertRecordingVip.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.mixVideo = this.mixVideo.bind(this);
        this.convertMono = this.convertMono.bind(this);
    }

    componentDidMount() {
        let recordingId = this.props.match.params.recordingId;
        if(recordingId != null) {
            this.getRecordingVip(recordingId);
        } else {
            this.setState({
                recording : {}
            });
        }
    }

    getRecordingVip(recordingId) {
        axios.post(global.config.apiDomain + '/rest/recordingvip/detail/' + recordingId).then((res) => {
            const recording = res.data;
            this.setState({
                recording: recording
            });
          })
    }

    getStatus(status) {
        switch(status) {
            case 'NEW':
                return <span className='text-secondary'>chưa xử lí</span>
            case 'PROCESSED':
                return <span className='text-success'>đã xử lí</span>
            case 'REVERTED':
                return <span className='text-danger'>đã revert</span>
            case 'PROCESSING':
                return <span className='text-danger'>đang xử lí</span>
            default:
                return <span className='text-secondary'>chưa xử lí</span>
        }
    }

    uploadFile(event) {
        var target = event.target;

        this.setState({
            loading: true
        });

        var id = target.id;
        var {recording} = this.state;
        var bucketName = "ikara-data";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = "recordings/vip/mp3/" + recording.id + "." + extension;
        var contentType = "audio/mp3";
        if (extension == "mp3" && id == "vipVocalUrl") {
            keyName = "recordings/vip/mp3/" + recording.id + "." + extension;
            contentType = "audio/mp3";
        } else if(extension == "mp4" && id == "vipMixedVideoUrl"){
            keyName = "recordings/vip/mp4/" + recording.id + "." + extension;
            contentType = "video/mp4";
        } else {
            if(id == "vipVocalUrl") {
                toast.warn("Vui lòng chọn file mp3.")
            } else if(id == "vipMixedVideoUrl") {
                toast.warn("Vui lòng chọn file mp4.")
            } else {
                toast.warn("UNKNOW")
            }
            this.setState({
                loading: false
            });

            return;
        }

        var self = this;
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    if(id == "vipVocalUrl") {
                        recording.vipVocalUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                        document.getElementById("vipVocalUrl").value = "";

                        if(recording.recordingType == "VIDEO") {
                            self.mixVideo();
                        }
                    } else if(id == "vipMixedVideoUrl")  {
                        recording.vipMixedVideoUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                        document.getElementById("vipMixedVideoUrl").value = "";
                    } else {
                        toast.error("ERRORRRRRRR")
                    }

                    this.setState({
                        recording: recording
                    });
                }.bind(this),error: function(jqXHR, exception) {
                    if(id == "vipVocalUrl") {
                        document.getElementById("vipVocalUrl").value = "";
                    } else if(id == "vipMixedVideoUrl"){
                        document.getElementById("vipMixedVideoUrl").value = "";
                    }
                    
                    toast.error(exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    saveRecordingVip() {
        this.setState({
            loading: true
        })

        const {recording} = this.state;
        
        axios.post(global.config.apiDomain + '/rest/recordingvip/save', JSON.stringify(recording), {
          headers : {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
            const {status, message} = res.data;
        
            if(status == "OK") {
                toast.success(message);
                this.getRecordingVip(recording.id)
            } else {
                toast.error(message);
            }
            this.setState({
                loading: false
            })
        }).catch(error => {
            toast.error(error);
            this.setState({
                loading: false
            })
        })
    }

    revertRecordingVip() {
        this.setState({
            loading: true
        })

        const {recording} = this.state;
        
        axios.post(global.config.apiDomain + '/rest/recordingvip/revert', JSON.stringify(recording), {
          headers : {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
            const {status, message} = res.data;
        
            if(status == "OK") {
                toast.success(message);
                this.getRecordingVip(recording.id)
            } else {
                toast.error(message);
            }
            this.setState({
                loading: false
            })
        }).catch(error => {
            toast.error(error);
            this.setState({
                loading: false
            })
        })
    }


    mixVideo() {
        toast.info("Bắt đầu trộn video");
        this.setState({
            loading: true
        })

        const {recording} = this.state;
        let parameters = "?vipvocalurl=" + recording.vipVocalUrl;
        axios.post(global.config.apiDomain + '/rest/recordingvip/mixvideo/' + recording.id + parameters).then((res) => {
            const {status, message, vipMixedVideoUrl} = res.data;

            if(status == "OK") {
                toast.success(message);
            } else {
                toast.error(message);
            }

            if(vipMixedVideoUrl != null && vipMixedVideoUrl != "") {
                recording.vipMixedVideoUrl = vipMixedVideoUrl;
            }

            this.setState({
                recording: recording,
                loading: false
            })
        }).catch(error => {
            toast.error(error);
            this.setState({
                loading: false
            })
        })
    }

    convertMono() {
        this.setState({
            loading: true
        })

        const {recording} = this.state;
        axios.post(global.config.apiDomain + '/rest/recordingvip/converttomono/' + recording.id).then((res) => {
            const {status, message, originalVocalMonoUrl} = res.data;

            if(status == "OK") {
                recording.originalVocalMonoUrl = originalVocalMonoUrl;
                toast.success(message);
            } else {
                toast.error(message);
            }

            if(originalVocalMonoUrl != null && originalVocalMonoUrl != "") {
                recording.originalVocalMonoUrl = originalVocalMonoUrl;
            }

            this.setState({
                recording: recording,
                loading: false
            })
        }).catch(error => {
            toast.error(error);
            this.setState({
                loading: false
            })
        })
    }

    getPerformanceType(performanceType) {
        switch(performanceType) {
            case 'SOLO':
                return "Đơn ca"
            case 'DUET':
                return "Song ca"
            case 'ASK4DUET':
                return "Chờ song ca"
            default:
                return "Đơn ca"
        }
    }

    render() {
        const model = bindModel(this);
        const {recording, loading} = this.state;

        if(recording == null) return <div id="content"></div>;

        return (
            <div id="content">
                <div className='row'>
                    
                </div>
                <div className="d-flex justify-content-between">
                    <Link style={{ width: "100px" }} to="/admin/recording-ai" className="btn btn-secondary my-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp; Trở về</Link>
                    <h2 className='text-center'>Thông tin bài thu ({this.getStatus(recording.status)})</h2>
                    <div></div>
                </div>
                    <div className='row mb-2 justify-content-center'>
                        <div className="col-2 my-auto">
                            <label>Tên bài hát</label>
                        </div>
                        <div className="col-6 col-lg-4">
                            <input 
                                className="form-control" 
                                value={recording.songName}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-2">
                            <a className='btn btn-primary text-white' href={recording.onlineRecordingUrl}>Xem <i className='fa fa-eye'></i></a>
                        </div>
                    </div>

                    <div className='row mb-2 justify-content-center'>
                        <div className="col-2 my-auto">
                            <label>Thể loại</label>
                        </div>
                        <div className="col-6 col-lg-4">
                            <input 
                                className="form-control" 
                                value={this.getPerformanceType(recording.performanceType) + " (" + recording.recordingType + ") "}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-2">
                        </div>
                    </div>

                    <div id="link" className="row mb-2 justify-content-center">
                        <div className="col-2 my-auto">
                            <label className="m-0 my-auto">Beat: </label>
                        </div>
                        {
                            recording.songUrl != null && recording.songUrl != "" &&
                            <div className="col-8 col-lg-6">
                                <audio height={150} src={recording.songUrl} controls></audio>
                            </div>
                        }
                    </div>

                    <div id="link" className="row mb-2 justify-content-center">
                        <div className="col-2 my-auto">
                            <label className="m-0 my-auto">Orignal vocal: </label>
                        </div>
                        <div className="col-8 col-lg-6">
                            <audio src={recording.originalVocalUrl} controls></audio>
                        </div>
                    </div>

                    <div id="link" className="row mb-2 justify-content-center">
                        <div className="col-2 my-auto">
                            <label className="m-0 my-auto">Orignal vocal (MONO): </label>
                        </div>
                        {recording.originalVocalMonoUrl != null && recording.originalVocalMonoUrl != "" 
                            ?
                            <div className="col-8 col-lg-6">
                                <audio src={recording.originalVocalMonoUrl} controls></audio>
                            </div> 
                            :
                            <div className="col-8 col-lg-6">
                                {this.state.loading 
                                    ?
                                    <button 
                                        style={{width: "150px"}} 
                                        className="btn btn-secondary" 
                                        type="button" 
                                        disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Get file mono   
                                    </button> 
                                    :
                                    <button 
                                        style={{width: "150px"}} 
                                        type="button" 
                                        className="btn btn-primary" 
                                        onClick={this.convertMono}>
                                            Get file mono 
                                    </button>
                                }
                            </div>
                        }
                        
                    </div>

                    {/* {recording.recordingType == "VIDEO" &&
                        <div id="link" className="row mb-2 justify-content-center">
                            <div className="col-2 my-auto">
                                <label className="m-0 my-auto">Original video: </label>
                            </div>
                            
                                <div className="col-8 col-lg-6">
                                    <video height={200} src={recording.originalVideoUrl} controls></video>
                                </div>
                        </div>
                    } */}

                    <div id="link" className="row mb-2 justify-content-center">
                        <div className="col-2 my-auto">
                            <label className="m-0 my-auto">Vip mixed audio: </label>
                        </div>
                        <div className="col-8 col-lg-6">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="vipVocalUrl"
                                onChange={this.uploadFile}
                            />
                            <label className="custom-file-label ml-3 mr-3" htmlFor="vipVocalUrl">Choose file mp3</label>
                        </div>
                    </div>

                    {
                        recording.vipVocalUrl != null && recording.vipVocalUrl != "" &&
                            <div id="link" className="row mb-2 justify-content-center">
                                <div className="col-2"></div>
                                <div className="col-8 col-lg-6">
                                    <audio src={recording.vipVocalUrl + "?v=" + new Date().getTime()} controls></audio>
                                </div>
                            </div>
                    }

                    {/* {recording.recordingType != "VIDEO" &&
                        <>
                            <div id="link" className="row mb-2 justify-content-center">
                                <div className="col-2 my-auto">
                                    <label className="m-0 my-auto">Vip mixed video: </label>
                                </div>
                                <div className="col-8 col-lg-6">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="vipMixedVideoUrl"
                                        onChange={this.uploadFile}
                                    />
                                    <label className="custom-file-label ml-3 mr-3" htmlFor="vipMixedVideoUrl">Choose file mp4</label>
                                </div>
                            </div>
                            {
                                recording.vipMixedVideoUrl != null && recording.vipMixedVideoUrl != "" &&
                                    <div id="link" className="row mb-2 justify-content-center">
                                        <div className="col-2"></div>
                                        <div className="col-8 col-lg-6">
                                            <video height={200} src={recording.vipMixedVideoUrl} controls></video>
                                        </div>
                                    </div>
                            }
                        </>
                    } */}

                    {recording.vipMixedVideoUrl != null && recording.vipMixedVideoUrl != "" 
                        ?
                        <div id="link" className="row mb-2 justify-content-center">
                            <div className="col-2 my-auto">
                                <label className="m-0 my-auto">Vip mixed video: </label>
                            </div>
                            <div className="col-8 col-lg-6">
                                <video height={200} src={recording.vipMixedVideoUrl + "?v=" + new Date().getTime()} controls></video>
                            </div>
                        </div>
                        :
                        <>
                        {(recording.recordingType == "VIDEO" && recording.vipVocalUrl != null && recording.vipVocalUrl != "") &&
                            <>
                                <div id="link" className="row mb-2 justify-content-center">
                                    <div className="col-2 my-auto">
                                        <label className="m-0 my-auto">Vip mixed video: </label>
                                    </div>
                                    <div className="col-8 col-lg-6">
                                        {this.state.loading 
                                        ?
                                        <button 
                                            style={{width: "150px"}} 
                                            className="btn btn-secondary" 
                                            type="button" 
                                            disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Mix video
                                        </button> 
                                        :
                                        <button 
                                            style={{width: "150px"}} 
                                            type="button" 
                                            className="btn btn-primary" 
                                            onClick={this.mixVideo}>
                                            Mix video
                                        </button>
                                        }
                                    </div>  
                                </div>
                            </>
                        }
                        </>
                    }

                    <div className='text-center my-3'>
                        {loading ? 
                            (
                                <>
                                    <button 
                                        style={{width: "120px"}} 
                                        className="btn btn-primary mx-1" 
                                        type="button" 
                                        disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Save  <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </button>
                                    {recording.status == "PROCESSED" &&
                                        <button 
                                            style={{width: "120px"}} 
                                            className="btn btn-secondary mx-1" 
                                            type="button" 
                                            disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Revert  <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    }
                                    
                                </>
                            ) :
                            (
                                <>
                                    <button 
                                        style={{width: "120px"}} 
                                        type="button" 
                                        className="btn btn-primary mx-1" 
                                        onClick={this.saveRecordingVip}>
                                            Save  <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </button>
                                    {recording.status == "PROCESSED" &&
                                        <button 
                                            style={{width: "120px"}} 
                                            type="button" 
                                            className="btn btn-secondary mx-1" 
                                            onClick={this.revertRecordingVip}>
                                                Revert  <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    }
                                    
                                </>
                            )
                        }
                    </div>

            </div>
        )
    }
}