import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';

export default class ModalUpdateIdol extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idol: null,
            loading: false,
        }
        this.updateIdol = this.updateIdol.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }
    
    reloadPage() {
        window.location.reload();
    }

    updateIdol() {

        const { idol } = this.state;

        if (idol.name == null || "" == idol.name) {
            toast.warning("Vui lòng thêm tên idol")
        }

        if (idol.uid == null || "" == idol.uid) {
            toast.warning("Vui lòng thêm UID idol")
        }

        // if (idol.signature == null || "" == idol.signature) {
        //     toast.warning("Vui lòng thêm trạng thái idol")
        // }

        if (idol.avatar == null || "" == idol.avatar) {
            toast.warning("Vui lòng thêm ảnh đại diện idol")
        }

        let url = global.config.apiDomain + "/rest/idol-manager/updateIdol"
        axios.post(url, idol)
            .then(res => {
                if (res.data.status == "FAILED") {
                    toast.warning(res.data.message);
                }
                else if (res.data.status == "OK") {
                    toast.success(res.data.message)
                    window.$("#ModalUpdateIdol").modal('hide')
                    setTimeout(() => {
                        this.reloadPage();
                    }, 2000);
                }
            })
            
    }

    uploadFile(event) {
        var target = event.target;
     //05Dec24 Check if the file size exceeds 1MB
     if (  target.files[0].size > 1048576) { // 1MB in bytes
        toast.error("Hình ảnh không được vượt quá dung lượng 1Mb.");
     }

        var allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

        if (!allowedImageTypes.includes(target.files[0].type)) {
            toast.warn("Vui lòng chọn một file ảnh hợp lệ (JPEG, PNG, hoặc GIF).");
            return;
        }

        this.setState({
            loading: true
        });

        var id = target.id;
        var bucketName = "ikara-data";
        var keyName = "avatars/" + target.files[0].name;
        var contentType = target.files[0].type;

        var self = this;
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType);

            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    let property = target.name;

                    let idol = this.state.idol;

                    var imageUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;

                    this.setState({
                        idol: {
                            ...idol,
                            [property]: imageUrl,
                        }
                    });
                    $("#previewImg").children().replaceWith(`<img src=${'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName}
                    style={{maxHeight : "300px"}} ></img>`)
                    toast.success("Tải ảnh lên thành công.");
                    console.log(imageUrl);
                }.bind(this),
                error: function (jqXHR, exception) {
                    toast.error("Lỗi khi tải lên ảnh.");
                    console.error(jqXHR, exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }



    componentWillReceiveProps(props) {
        const { idol } = props;

        if (idol == null) {
            let json = {
                avatarUrl: ""
            }
            this.setState({
                idol: json,
                loading: false
            })

        }

        this.setState({
            idol: idol
        })
        if (idol != null) {

            if (idol.avatar) {
                $("#previewImg").children().replaceWith(`<img src=${idol.avatar}
                style={{maxHeight : "300px"}} ></img>`)
            }

            idol && idol.deleted ? $("#deletedSelect").val(0) : $("#deletedSelect").val(1)

        }
    }

    render() {
        const model = bindModel(this);
        const { idol, loading } = this.state;
        return (
            <div>
                <div className="modal fade" id="ModalUpdateIdol" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật thông tin Idol</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.reloadPage}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <div id="idolFid" className="row mb-2">
                                    <div className="col-3">
                                        <label>Facebook ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            readOnly="true"
                                            {...model('idol.id')} />
                                    </div>
                                </div>
                                <div id="idolUid" className="row mb-2">
                                    <div className="col-3">
                                        <label>UID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            placeholder="Nhập UID"
                                            {...model('idol.uid')} />
                                    </div>
                                </div>
                                <div id="idolName" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên Idol</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            placeholder="Nhập tên Idol"
                                            {...model('idol.name')} />
                                    </div>
                                </div>
                                <div id="signature" className="row mb-2">
                                    <div className="col-3">
                                        <label>Trạng thái</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            placeholder="Nhập trạng thái"
                                            {...model('idol.signature')} />
                                    </div>
                                </div>
                                <div id="avatarUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ảnh đại diện</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="avatar"
                                            onChange={this.uploadFile}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="miniBackgroundUrl">Choose file</label>
                                    </div>
                                </div>
                                <div id="previewThumbnail" className="row mb-2">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-9" id="previewImg">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button className='btn btn-secondary ml-2 mr-2' data-dismiss="modal" onClick={this.reloadPage}>Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.updateIdol}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}