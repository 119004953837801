import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

function convertTimestampToVietnamTime(timestamp) {
    const date = new Date(timestamp);
    const options = {
        timeZone: 'Asia/Ho_Chi_Minh',
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    return date.toLocaleString('vi-VN', options);
}

function SmartLookManager() {
    const [loading, setLoading] = useState(true);
    const [resetSmartLook, setResetSmartLook] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        'ikara': false,
        'yokara': false,
        'allUser': false,
        'newDevice': false,
        'vipUser': false,
        'designatedUser': false,
        'noDay': 7,
        'ratio': 25
    });

    const handleChange = (event) => {
        const { name, checked, value } = event.target;

        if(name == "noDay" || name == "ratio") {
            if (!/^\d*$/.test(value)) {
                return;
            }

            if (Number(value) > 100 || Number(value) < 0) {
                return;
            }

            setSelectedOptions((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setSelectedOptions((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
        }


    };

    const setSmartLook = async () => {
        setLoading(true);
        const res = await axios.post(global.config.apiDomain + '/rest/smartLook/set-smartLook', JSON.stringify(selectedOptions), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setLoading(false);
        if (res.data.status === '200') {
            toast.success("Set SmartLook thành công");
        } else {
            toast.error("Set SmartLook không thành công");
        }
    }

    const fetchData = async () => {
        try {
            const res = await axios.get(global.config.apiDomain + '/rest/smartLook/get-smartLook', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const { ikara, yokara, newDevice, vipUser, designatedUser, noDay, ratio, allUser } = res.data;
            setSelectedOptions({
                ikara: ikara,
                yokara: yokara,
                allUser: allUser,
                newDevice: newDevice,
                vipUser: vipUser,
                designatedUser: designatedUser,
                noDay: noDay,
                ratio: ratio
            });
        } catch (error) {
            console.error('Lỗi khi gọi API:', error);
        }
    };

    const reset = () => {
        setSelectedOptions({
            'ikara': false,
            'yokara': false,
            'allUser': false,
            'newDevice': false,
            'vipUser': false,
            'designatedUser': false,
            'noDay': 7,
            'ratio': 25
        });
        setResetSmartLook(true);
    }

    useEffect(() => {
        if(resetSmartLook){
            setSmartLook();
            setResetSmartLook(false);
        }
    }, [resetSmartLook]);

    useEffect(() => {
        fetchData();
        setLoading(false);
    }, []);

    return (
        <>
            <div
                className="container"
                style={{
                    marginTop: "100px",
                    marginBottom: "100px",
                }}
            >
                <h1 className="text-center">SmartLook Manager</h1>
                <div className="filter-container">
                    <p className="text-danger">Sau khi chọn xong tệp user và loại user, nhấn SetSmartLook</p>
                </div>
                {
                    loading === true ? (
                        <h1 className='text-center'>Loading...</h1>
                    ) : (
                        <div>
                            <div>
                                <p className="text-info">Tệp user:</p>
                                <div className="d-flex justify-content-start align-items-center mb-3">
                                    <div style={{ marginRight: "24px" }}>
                                        <input
                                            type="checkbox"
                                            name="ikara"
                                            value="option1"
                                            checked={selectedOptions["ikara"]}
                                            onChange={handleChange}
                                        /> Ikara
                                    </div>
                                    <div>
                                        <input
                                            type="checkbox"
                                            name="yokara"
                                            value="option1"
                                            checked={selectedOptions["yokara"]}
                                            onChange={handleChange}
                                        /> Yokara
                                    </div>
                                </div>
                                <p className="text-info">Loại user:</p>
                                <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
                                <   div style={{ marginRight: "24px" }}>
                                        <input
                                            type="checkbox"
                                            name="allUser"
                                            value="option4"
                                            checked={selectedOptions["allUser"]}
                                            onChange={handleChange}
                                        /> AllUser
                                    </div>

                                    <div style={{ marginRight: "24px" }}>
                                        <input
                                            type="checkbox"
                                            name="newDevice"
                                            value="option4"
                                            checked={selectedOptions["newDevice"]}
                                            onChange={handleChange}
                                        /> New Device
                                    </div>


                                    <div style={{ marginRight: "24px" }}>
                                        <input
                                            type="checkbox"
                                            name="vipUser"
                                            value="option2"
                                            checked={selectedOptions["vipUser"]}
                                            onChange={handleChange}
                                        /> Vip User
                                    </div>

                                    <div>
                                        <input
                                            type="checkbox"
                                            name="designatedUser"
                                            value="option3"
                                            checked={selectedOptions["designatedUser"]}
                                            onChange={handleChange}
                                        /> User chỉ định
                                    </div>
                                </div>

                                <div className="d-flex justify-content-start align-items-center mt-3 mb-3">

                                    <div style={{ marginRight: "24px" }}>
                                    Số ngày quay (Đối với New Device)
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="noDay"
                                            value={selectedOptions["noDay"]}
                                            onChange={handleChange}
                                            min={0}
                                            max={90}
                                        /> 
                                    </div>

                                    <div>
                                    Tỉ lệ (%)
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="ratio"
                                            value={selectedOptions["ratio"]}
                                            onChange={handleChange}
                                            min={0}
                                            max={100}
                                        /> 
                                    </div>
                                </div>

                            </div>

                            <button className="btn btn-primary" onClick={setSmartLook} style={{ marginRight: "20px" }}>
                                Set SmartLook
                            </button>

                            <button className="btn btn-danger" onClick={reset}>
                                Reset
                            </button>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default SmartLookManager;
