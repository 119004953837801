import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';

import bindModel from '../../../common/bindModel.js';

// const $ = window.$;

export default class ModalUpdateColorFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterModel: {
                type: '',
                _id: '',
                name: '',
                thumbnail: '',
                resourceUrl: '',
                order: 0,
                minVipLevel: 0,
                minLevel: 0,
                extraProperties: []
            },
            loading: false,
            count: 0
        }

        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.uploadAnimationToS4 = this.uploadAnimationToS4.bind(this);
        this.createOrUpdateFilter = this.createOrUpdateFilter.bind(this);
        this.addProperty = this.addProperty.bind(this);
    }

    componentWillReceiveProps(props) {
        $('#properties').html('');
        let filterModel = props.filterModel;
        this.setState({
            filterModel: filterModel,
        })

        if (filterModel && filterModel.extraProperties) {
            let size = filterModel.extraProperties.length;
            for (let i = 0; i < size; i++) {
                $('#properties').append(`
                    <div class = "row" id = "row_${i}">
                        <input 
                            class= "form-control mb-2 ml-2 mr-2 col-4"
                            type = "text"
                            id = "extra_property_${i}"
                            placeholder= "Nhập thuộc tính bổ sung..."
                        />
                        <button
                            class = "btn btn-danger mb-2 mr-2 col-1"
                            id="delete_${i}"
                        >X</button>
                    </div>
                `)

                let extraProperty = filterModel.extraProperties[i];
        
                const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                if (regexColor.test('#' + extraProperty)) {
                    $(`#extra_property_${i}`).val(extraProperty);
                    $(`#row_${i}`).append(`<input id = "lipColor_${i}" class = "form-control mb-2 ml-2 mr-2 col-1" style= "background-color: #${extraProperty}" readonly></input>`)
                } else {
                    let fileName = extraProperty.substring(extraProperty.lastIndexOf('/') + 1, extraProperty.length);
                    $(`#extra_property_${i}`).val(fileName);
                    $(`#row_${i}`).append(`<img id = "lipColor_${i}" src = ${extraProperty}  alt = ${extraProperty}></img>`)
                } 
       
                $(`#extra_property_${i}`).on('change', () => {
                    let extraProperty = $(`#extra_property_${i}`).val();
                    if (extraProperty != null && extraProperty != '') {
                        $(`#lipColor_${i}`).remove();
                        const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                        if (regexColor.test('#' + extraProperty)) {
                            $(`#row_${i}`).append(`<input id = "lipColor_${i}" class = "form-control mb-2 ml-2 mr-2 col-1" style= "background-color: #${extraProperty}" readonly></input>`)
                        } else {
                            $(`#row_${i}`).append(`<img id = "lipColor_${i}" src = ${extraProperty}  alt = ${extraProperty}></img>`)
                        }
                    }
                })
        
                $(`#delete_${i}`).on('click', () => {
                    $(`#row_${i}`).remove();
                }) 
            }
            this.setState({
                count: size
            })
        }
    }

    addProperty() {
        let count = this.state.count;

        $('#properties').append(`
            <div class = "row" id = "row_${count}">
                <input 
                    class= "form-control mb-2 ml-2 mr-2 col-4"
                    type = "text"
                    id = "extra_property_${count}"
                    placeholder= "Nhập thuộc tính bổ sung..."
                />
                <button
                    class = "btn btn-danger mb-2 mr-2 col-1"
                    id="delete_${count}"
                >X</button>
            </div>
        `)

        $(`#extra_property_${count}`).on('change', () => {
            let extraProperty = $(`#extra_property_${count}`).val();
            if (extraProperty != null && extraProperty != '') {
                $(`#lipColor_${count}`).remove();
                const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                if (regexColor.test('#' + extraProperty)) {
                    $(`#row_${count}`).append(`<input id = "lipColor_${count}" class = "form-control mb-2 ml-2 mr-2 col-1" style= "background-color: #${extraProperty}" readonly></input>`)
                } else {
                    $(`#row_${count}`).append(`<img id = "lipColor_${count}" src = ${extraProperty}  alt = ${extraProperty}></img>`)
                }
            }
        })

        $(`#delete_${count}`).on('click', () => {
            $(`#row_${count}`).remove();
        })

        this.setState({
            count: count + 1
        })

    }

    createOrUpdateFilter() {
        let { filterModel, count } = this.state;

        if (
            filterModel == undefined ||
            !filterModel._id ||
            !filterModel.name ||
            !filterModel.thumbnail ||
            filterModel.order == 0
        ) {
           return toast.error('Nhập đầy đủ thông tin');
        }

        if (
            filterModel.type == 'COLOR' && 
            !filterModel.resourceUrl
        ) {
           return toast.error('Thiếu ảnh');
        }

        if (
            filterModel.type == 'BEAUTY' && filterModel.minValue >= filterModel.maxValue
        ) {
           return toast.error('Sai giá trị min/max');
        }

        filterModel._id = filterModel._id.toUpperCase();
        filterModel.extraProperties = [];
        for (let i = 0; i < count; i++) {
            let value = $(`#extra_property_${i}`).val();
            if (value) {
                const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                if (regexColor.test('#' + value)) {
                    filterModel.extraProperties.push(value);
                } else {
                    filterModel.extraProperties.push('https://data4.ikara.co/data/minio/ikara-data/filter/beauty/' + value);
                }
            }
        }

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/deepARFilter/createOrUpdateFilter', filterModel)
            .then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateFilter").modal("hide");
                    this.props.resetList(filterModel.type);
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error);
            })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        let target = event.target;

        //05Dec24 Check if the file size exceeds 1MB
        if (  target.files[0].size > 1048576) { // 1MB in bytes
            toast.error("Hình ảnh không được vượt quá dung lượng 1Mb.");
             return;
        }

        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        // // Allowing file type
        // let allowedExtensions = /(\.svg)$/i;

        // if (!allowedExtensions.exec(target.value)) {
        //     toast.error("Chỉ upload file png");
        //     return ;
        // }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;

        let filterModel = this.state.filterModel;
        let bucketName = "ikara-data/images/deep-ar-filter/" + filterModel.type;
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        if (this.state.filterModel != null && this.state.filterModel._id != '') {
            keyName = this.state.filterModel._id + "-" + property + "." + extension;
        }

        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();

        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: async function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        filterModel: {
                            ...filterModel,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    uploadAnimationToS4(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;

        let filterModel = this.state.filterModel;
        let bucketName = "ikara-data/images/deep-ar-filter/" + filterModel.type;
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        if (this.state.filterModel != null && this.state.filterModel._id != '') {
            keyName = this.state.filterModel._id + "-" + property + "." + extension;
        }
        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();

        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        filterModel: {
                            ...filterModel,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }




    render() {
        const model = bindModel(this);
        const { loading, filterModel } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateFilter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Bộ lọc {(filterModel && filterModel.type == 'COLOR')? 'Màu' : "Trang điểm"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Thứ tự</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            id="order"
                                            className="form-control mb-2"
                                            type="number"
                                            name="order"
                                            placeholder="Nhập Thứ tự ..."
                                            {...model('filterModel.order')} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            id="_id"
                                            className="form-control mb-2"
                                            type="text"
                                            name="_id"
                                            placeholder="Nhập ID ..."
                                            {...model('filterModel._id')} />
                                    </div>
                                </div>
                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="name"
                                            placeholder="Nhập Tên ..."
                                            {...model('filterModel.name')} />
                                    </div>
                                </div>
                              
                                <div id="thumbnail" className="row mb-2">
                                    <div className="col-3">
                                        <label>Minh họa</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="thumbnail"
                                            onChange={this.uploadAnimationToS4} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="thumbnail">Choose file</label>
                                    </div>
                                </div>
                                {(this.props.filterModel && filterModel.type == 'COLOR') ? (
                                    <div id="resourceUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Bộ lọc</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                name="resourceUrl"
                                                onChange={this.uploadAnimationToS3} />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="resourceUrl">Choose file</label>
                                        </div>
                                    </div>
                                ) : ""}
                                
                               
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label></label>
                                    </div>
                                    <div className="col-4">
                                        <label>Minh họa</label>
                                    </div>
                                    {(this.props.filterModel && filterModel.type == 'COLOR') ? (
                                        <div className="col-4">
                                            <label>Bộ lọc</label>
                                        </div>
                                    ) : ""}
                                    
                                    <div className="col-1">
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label></label>
                                    </div>
                                    <div className="col-4">
                                        {(this.props.filterModel && filterModel.thumbnail) ? (
                                            <>
                                                <img src={filterModel.thumbnail} />
                                            </>
                                        ) : ""}
                                    </div>
                                    {(this.props.filterModel && filterModel.type == 'COLOR') ? (
                                        <div className="col-4">
                                           {(this.props.filterModel && filterModel.resourceUrl) ? (
                                               <>
                                                   <img src={filterModel.resourceUrl} />
                                               </>
                                           ) : ""}
                                       </div>
                                    ) : ""}
                                 
                                    <div className="col-1">
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Level</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            id="minLevel"
                                            className="form-control mb-2"
                                            type="number"
                                            name="minLevel"
                                            placeholder="Nhập Level tối thiểu ..."
                                            {...model('filterModel.minLevel')} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Level Vip</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            id="minVipLevel"
                                            className="form-control mb-2"
                                            type="number"
                                            name="minVipLevel"
                                            placeholder="Nhập Level Vip tối thiểu ..."
                                            {...model('filterModel.minVipLevel')} />
                                    </div>
                                </div>
                                {(this.props.filterModel && filterModel.type == 'BEAUTY') ? (
                                    <>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label>Min Value</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    id="minValue"
                                                    className="form-control mb-2"
                                                    type="number"
                                                    name="minValue"
                                                    placeholder="Nhập Min Value ..."
                                                    {...model('filterModel.minValue')} />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label>Max Value</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    id="maxValue"
                                                    className="form-control mb-2"
                                                    type="number"
                                                    name="maxValue"
                                                    placeholder="Nhập Max Value ..."
                                                    {...model('filterModel.maxValue')} />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label>Extra Property</label>
                                            </div>
                                            <div className="col-9">
                                                <div id='properties'>
                                                </div>

                                                <button className='btn btn-success'                             
                                                    onClick={this.addProperty}
                                                >+</button>
                                            </div>
                                        </div>
                                    </>
                                ) : ""}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.createOrUpdateFilter}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}