import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

export default class YoutubeMidiPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      songs: [],
      cursor: null,
      loading: false,
      statusButtonLoadPage: false,
      filterStatus: 'ALL',
      sortOrder: 'NEWEST'
    }

    this.getSongs = this.getSongs.bind(this);
    this.changeStatusProcessing = this.changeStatusProcessing.bind(this);
  }


  componentDidMount() {
    this.getSongs();
  }


  getSongs(reset = false) {
    const { loading, filterStatus, sortOrder } = this.state;

    if (!loading) {
      this.setState({
        loading: true,
        ...(reset && { songs: [], cursor: null })
      });

      let json = {
        cursor: reset ? null : this.state.cursor,
        midiStatus: filterStatus === 'ALL' ? null : filterStatus,
        sortOrder: sortOrder
      };

      axios.post(global.config.apiDomain + '/rest/youtubemidi/list', json)
        .then(res => {
          let status = true;
          if (res.data.cursor === "END") {
            status = false;
          }

          this.setState({
            songs: reset ? res.data.songs : this.state.songs.concat(res.data.songs),
            cursor: res.data.cursor,
            statusButtonLoadPage: status,
            loading: false
          });
        });
    }
  }

  renderSortOptions() {
    const { sortOrder, loading } = this.state;

    return (
      <div className="sort-container mb-3">
        <label htmlFor="sortOrder" className="me-2">Sắp xếp: </label>
        <select
          id="sortOrder"
          value={sortOrder}
          onChange={(e) => {
            this.setState({ sortOrder: e.target.value }, () => {
              this.getSongs(true);
            });
          }}
          className="form-select w-auto"
          disabled={loading} ải
        >
          <option value="NEWEST">Mới nhất</option>
          <option value="OLDEST">Cũ nhất</option>
        </select>
      </div>
    );
  }


  changeStatusProcessing(recordingId) {
    axios.post(global.config.apiDomain + '/rest/youtubemidi/processing/' + recordingId)
      .then((res) => { });
  }
  cancelProcessing(recordingId) {
    axios.post(global.config.apiDomain + '/rest/youtubemidi/cancelProcessing/' + recordingId)
      .then((res) => {
        if (res.data.status === "OK") {
          this.setState((prevState) => ({
            songs: prevState.songs.map(song =>
              song.videoId === recordingId
                ? { ...song, midiStatus: 'NEW' }
                : song
            )
          }));
        }
      })
      .catch(err => {
        console.error("Error canceling processing:", err);
      });
  }

  getStatus(status) {
    switch (status) {
      case 'NEW':
        return <i className="fa-solid fa-ban fa-xl text-danger"></i>
      case 'PROCESSED':
        return <i class="fa-solid fa-circle-check fa-xl text-success"></i>
      case 'PROCESSING':
        return <i class="fas fa-cog fa-spin fa-xl text-primary"></i>
      // case 'REVERTED':
      //     return <i className="fa-solid fa-refresh fa-xl text-secondary"></i>
      // case 'CANCELED':
      //   return <i className="fa-solid fa-trash fa-xl text-secondary"></i>
      default:
        return <i className="fa-solid fa-ban fa-xl text-danger"></i>
    }

  }

  renderFilterOptions() {
    const { loading, filterStatus } = this.state;

    return (
      <div className="filter-container mb-3">
        <label htmlFor="filterStatus" className="me-2">Lọc theo trạng thái: </label>
        <select
          id="filterStatus"
          value={filterStatus}
          onChange={(e) => {
            this.setState({ filterStatus: e.target.value }, () => {
              this.getSongs(true);
            });
          }}
          className="form-select w-auto"
          disabled={loading}
        >
          <option value="ALL">Tất cả</option>
          <option value="NEW">Chưa xử lý</option>
          <option value="PROCESSING">Đang xử lý</option>
          <option value="PROCESSED">Đã xong</option>
          {/* <option value="CANCELED">Đã hủy</option> */}
        </select>
      </div>
    );
  }




  render() {
    const { songs, statusButtonLoadPage, loading } = this.state;

    return (
      <>
        <div id="content">
          <div className="container-fluid">
            <h1 className="text-center">Danh sách Beat</h1>
            <div className="row mb-3">
              {this.renderFilterOptions()}
              {this.renderSortOptions()}
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">UID</th>
                  <th scope="col">Trạng thái</th>
                  <th scope="col">Thời gian</th>
                  <th scope="col">VideoID</th>
                  <th scope="col">Thời gian Upload Midi</th>
                  <th scope="col">Tên Beat</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {songs && songs.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle">{index + 1}</td>
                      <td className="align-middle">#{item.uid}</td>
                      <td className="align-middle">{this.getStatus(item.midiStatus)}</td>
                      <td className="align-middle">{new Date(item.addTime).toLocaleString()}</td>
                      <td className="align-middle"><strong>{item.videoId}</strong></td>
                      <td className="align-middle">
                        {item.uploadMidiTime && item.uploadMidiTime !== 0
                          ? new Date(item.uploadMidiTime).toLocaleString()
                          : ''}
                      </td>
                      <td className="align-middle">{item.songName}</td>
                      <td className="align-middle" style={{ width: "100px" }}>
                        <Link to={'/admin/youtube-midi/detail/' + item.videoId}>
                          <button
                            onClick={() => this.changeStatusProcessing(item.videoId)}
                            className='btn btn-success'
                          >
                            Xử lý
                          </button>
                        </Link>

                      </td>
                      {item.midiStatus === "PROCESSING" && (
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => this.cancelProcessing(item.videoId)}
                          >
                            Hủy xử lý
                          </button>
                        </td>
                      )}

                    </tr>
                  )
                })}
              </tbody>
            </table>
            {
              statusButtonLoadPage ?
                (<div onClick={() => this.getSongs(false)} className="view_more w-100 text-center mb-lg-4">
                  <a className="d-none d-md-block">
                    <span>{loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                  </a>
                  <a className="view_more_mobile d-md-none">
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </a>
                </div>) :
                ('')
            }
          </div>
        </div>
      </>
    );
  }
}