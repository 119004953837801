import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom'; // Đảm bảo bạn đã import Link nếu cần
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
function PaginatedTable({ newDevices, pageSize }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'addTime', direction: 'asc' });

    const [isUserLoging, setIsUserLoging] = useState('');
    const [languageValue, setLanguageValue] = useState('');
    const [platformValue, setPlatformValue] = useState('');
    const [paidUser, setPaidUser] = useState('');
    const [lastLogin, setLastLogin] = useState('');
    const [filteredDevices, setFilteredDevices] = useState(newDevices);

    const itemsPerPage = pageSize;
    const pagesVisited = currentPage * itemsPerPage;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "isUserLoging") setIsUserLoging(value);
        if (name === "languageValue") setLanguageValue(value);
        if (name === "platformValue") setPlatformValue(value);
        if (name === "paidUser") setPaidUser(value);
    };


    const exportExcel = () => {
        // Chỉ định các trường bạn muốn xuất
        const fieldsToExport = ["deviceId", "uid", "name", "addTime", "language", "platform", "model", "paidUser", "lastLogin", "setSmartLook", "haveSessionSmartLook", "linkSessionSmartLook"];
    
        // Lọc các trường từ dữ liệu
        const filteredDevices = newDevices.map(device => {
            const filteredDevice = {};
            fieldsToExport.forEach(field => {
                if (device.hasOwnProperty(field)) {
                    if(field == "addTime") {
                        filteredDevice[field] = new Date(device[field]).toLocaleString();
                    } else {
                        filteredDevice[field] = device[field];
                    }
                }
            });
            return filteredDevice;
        });
    
        // Tạo bảng tính Excel từ dữ liệu đã lọc
        const worksheet = XLSX.utils.json_to_sheet(filteredDevices);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
    
        const file = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(file, "smart_look_data.xlsx");
    }

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleLastLoginChange = (e) => {
        setLastLogin(e.target.value);
    };

    const handleSearch = () => {
        let filteredData = newDevices;

        // Lọc theo trạng thái đăng nhập
        if (isUserLoging) {
            filteredData = filteredData.filter(item =>
                isUserLoging === "loging" ? item.uid : !item.uid
            );
        }

        // Lọc theo app
        if (languageValue) {
            filteredData = filteredData.filter(item =>
                item.language === languageValue
            );
        }

        // Lọc theo platform
        if (platformValue) {
            filteredData = filteredData.filter(item =>
                item.platform === platformValue
            );
        }

        // Lọc theo paid user
        if (paidUser) {
            filteredData = filteredData.filter(item =>
                String(item.paidUser) === paidUser
            );
        }

        // Lọc theo ngày đăng nhập
        if (lastLogin) {
            filteredData = filteredData.filter(item =>
                new Date(item.lastLogin).toLocaleDateString() === new Date(lastLogin).toLocaleDateString()
            );
        }

        // Cập nhật dữ liệu đã lọc vào state
        setFilteredDevices(filteredData);
    };

    const sortedDevices = React.useMemo(() => {
        const sortableItems = [...newDevices];
        sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        return sortableItems;
    }, [newDevices, sortConfig]);

    const displayDevices = sortedDevices.slice(pagesVisited, pagesVisited + itemsPerPage);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    return (
        <div className='pagination-container' style={{marginBottom: "40px"}}>

            {/* <div className="search-container" style={{ marginBottom: "10px" }}>
                <select name="isUserLoging" value={isUserLoging} onChange={handleInputChange}>
                    <option value="">Check Device</option>
                    <option value="loging">Có tài khoản đang đăng nhập</option>
                    <option value="isLoging">Không có tài khoản nào đang đăng nhập</option>
                </select>
                <select name="languageValue" value={languageValue} onChange={handleInputChange}>
                    <option value="">App</option>
                    <option value="vi">iKara</option>
                    <option value="en.yokara">Yokara</option>
                </select>
                <select name="platformValue" value={platformValue} onChange={handleInputChange}>
                    <option value="">Platform</option>
                    <option value="IOS">IOS</option>
                    <option value="ANDROID">ANDROID</option>
                </select>
                <select name="paidUser" value={paidUser} onChange={handleInputChange}>
                    <option value="">Paid User</option>
                    <option value="true">Đã chi tiêu</option>
                    <option value="false">Chưa chi tiêu</option>
                </select>
                <label className='m-0 mr-1' htmlFor="lastLogin">Last Login: </label>
                <input type="date" onChange={handleLastLoginChange} />
                <button style={{ minWidth: '80px' }} onClick={handleSearch}>Tìm kiếm</button>
                <button style={{ minWidth: '80px' }} onClick={exportExcel}>Export</button>
                <div style={{ minWidth: '80px' }} className="quantity">Số lượng: {filteredDevices.length}</div>
            </div> */}

            <table className="table table-hover">
                <thead>
                    <tr className="text-center">
                        <th className="align-middle" scope="col">No.</th>
                        <th className="align-middle" scope="col">
                            AddTime
                            <button
                                className="btn btn-outline-secondary ml-1 p-1"
                                onClick={() => requestSort("addTime")}>
                                <i className="fas fa-sort" />
                            </button>
                        </th>
                        <th className="align-middle" scope="col">DeviceId</th>
                        <th className="align-middle" scope="col">App</th>
                        <th className="align-middle" scope="col">Platform</th>
                        <th className="align-middle" scope="col">Uid</th>
                        <th className="align-middle" scope="col">Name</th>
                        <th className="align-middle" scope="col">PaidUser</th>
                        <th className="align-middle" scope="col">Reply Admin</th>
                        <th className="align-middle" scope="col">LastLogin</th>
                        <th className="align-middle" scope="col" style={{ width: "150px" }}>
                            SmartLook
                            <button
                                className="btn btn-outline-secondary ml-1 p-1"
                                onClick={() => {requestSort("setSmartLook")}}>
                                <i className="fas fa-sort" />
                            </button>
                        </th>
                        <th className="align-middle" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {displayDevices.map((item, index) => {
                        return (
                            <tr key={item.deviceId}>
                                <td className="align-middle text-center" scope="col">{index + 1 + currentPage * itemsPerPage}</td>
                                <td className="align-middle text-center" scope="col">{new Date(item.addTime).toLocaleString()}</td>
                                <td className="align-middle text-center" scope="col">{item.deviceId}</td>
                                <td className="align-middle text-center" scope="col">{item.language}</td>
                                <td className="align-middle text-center" scope="col">{item.platform}</td>
                                <td className="align-middle text-center" scope="col">{item.uid}</td>
                                <td className="align-middle text-center" scope="col">{item.name}</td>
                                {!item.paidUser ?
                                    <td className="align-middle text-center text-danger" scope="col"><strong>FALSE</strong></td> :
                                    <td className="align-middle text-center text-success" scope="col"><strong>TRUE</strong></td>}
                                {!item.replyAdmin ?
                                    <td className="align-middle text-center text-danger" scope="col"><strong>FALSE</strong></td> :
                                    <td className="align-middle text-center text-success" scope="col"><strong>TRUE</strong></td>}
                                {item.uid && item.uid !== '' ?
                                    <td scope="col" className="align-middle text-center">{new Date(item.lastLogin).toLocaleString()}</td>
                                    :
                                    <td scope="col" className="align-middle text-center">Chưa đăng nhập</td>}
                                {(item.setSmartLook) ? (
                                    <>
                                        {item.linkSessionSmartLook && item.linkSessionSmartLook !== "" ?
                                            <td className="align-middle text-center text-success" scope="col">
                                                <a href={item.linkSessionSmartLook} target="_blank" rel="noopener noreferrer">
                                                    <strong>ENABLE</strong>
                                                </a>
                                            </td>
                                            :
                                            <td className="align-middle text-center text-danger" scope="col">
                                                <strong>ENABLE {item.errorSessionSmartLook ? '('+ item.errorSessionSmartLook + ')' : ''}</strong>
                                            </td>
                                        }
                                    </>
                                ) : (
                                    item.linkSessionSmartLook ?
                                        <td className="align-middle text-center text-primary" scope="col">
                                            <a href={item.linkSessionSmartLook} target="_blank" rel="noopener noreferrer">
                                                <strong>DISABLE</strong>
                                            </a>
                                        </td> :
                                        <td className="align-middle text-center text-danger" scope="col"><strong>DISABLE</strong></td>
                                )}
                                <td className="align-middle text-center" scope="col">
                                    {item.uid && item.uid !== '' ?
                                        <Link to={`/admin/support-new/${item.uid}`}>
                                            <button type="button" className="btn btn-success">Nhắn Tin</button>
                                        </Link> : <Link to={`/admin/support-new/${item.deviceId}`}>
                                            <button type="button" className="btn btn-success">Nhắn Tin</button>
                                        </Link>}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/* Pagination */}
            <ReactPaginate
            className=''
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={Math.ceil(newDevices.length / itemsPerPage)}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center float-none"}
                activeClassName={"active"}
            />
        </div>
    );
}

export default PaginatedTable;
