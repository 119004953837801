import React, { Component, createRef } from 'react';
import '../css/chatpage.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import $ from 'jquery';
import Firebase from '../../../components/Firebase';
import lodash from 'lodash'
import { Base64 } from 'js-base64';
import MyContext from '../../../common/MyContext';
import MessageList from './MessageList';
import MessageDetailList from './MessageDetailList.js';
import ReactScroll from 'react-scrollable-feed';

export default class MessagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: { facebookId: props.param },
            targetUser: null,
            roomId: "",
            messages: [],
            messageDetails: [],
            searchUsers: [],
            cursorMessageDetails: null,
            loaded: false,
            isFirst: false,
            loading: false,
            listenFirebaseMessages: null,
            listenFirebaseMessgeDetails: null,
            message: "",
            searchKeyword: "",
            isUserActive: false,
        }
        this.ikaraNode = Firebase.database().ref("ikara");
        this.functions = Firebase.functions();
        this.auth = Firebase.auth();
        this.getFirebaseToken = this.getFirebaseToken.bind(this);
        this.accessToken = this.accessToken.bind(this);
        this.listenFirebaseMessages = this.listenFirebaseMessages.bind(this);
        this.listenFirebaseMessgeDetails = this.listenFirebaseMessgeDetails.bind(this);
        this.getMessageDetail = this.getMessageDetail.bind(this);
        this.loadMoreMessageDetails = this.loadMoreMessageDetails.bind(this);
        this.offListen = this.offListen.bind(this);
        this.handleTargetUser = this.handleTargetUser.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.acceptSendVideo = this.acceptSendVideo.bind(this);
        this.isWithin30Days = this.isWithin30Days.bind(this);
        this.chatContainerRef = createRef();
        this.messagesBoxRef = createRef();
        this.currentTimeout = null;  
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.getFirebaseToken();
        }
    }

    componentDidUpdate(prevProps) {
        // Kiểm tra nếu prop user thay đổi
        if (this.props.param && this.props.param !== prevProps.param) {
            this.setState({
                user: { facebookId: this.props.param },
                targetUser: null,
                roomId: "",
                messages: [],
                messageDetails: [],
                searchUsers: [],
                cursorMessageDetails: null,
                loaded: false,
                isFirst: false,
                loading: false,
                listenFirebaseMessages: null,
                listenFirebaseMessgeDetails: null,
                message: "",
                searchKeyword: "",
                isUserActive: false,
            }, () => {
                this.getFirebaseToken();
            });
        }
    }

    handleScroll() {
        const { loading, loaded } = this.state;
        if ($('.chat-box').scrollTop() < 150 && !loading && loaded) {
            this.state.loading = true;
            this.loadMoreMessageDetails()
        }
    }

    getFirebaseToken() {
        let { user } = this.state;
        axios.post(global.config.apiDomain + "/rest/admin/getFirebaseToken", user.facebookId, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.data != null) {
                    this.setState({ user: res.data.user }, () => {
                        this.accessToken();
                    });
                }
            })
            .catch(error => {
                console.error("Error fetching Firebase token:", error);
            });
    }

    acceptSendVideo() {
        let acceptSendVideo = false;
        if (this.state.messageDetails && this.state.messageDetails.length > 1) {
            let firstUser = this.state.messageDetails[0].userId;
            this.state.messageDetails.map(value => {
                if (value.userId && value.userId !== firstUser) {
                    acceptSendVideo = true;
                }
            })
        }
        return acceptSendVideo;
    }

    accessToken() {
        const { user } = this.state;
        this.auth.signInWithCustomToken(user.firebaseToken)
            .then(res => {
                this.listenFirebaseMessages();
            }).catch(error => {
                this.listenFirebaseMessages();
            });
    }

    uploadImage = (event) => {
        if (window.confirm("Bạn có chắc muốn gửi file này ?")) {
            var target = event.target;
            if (target.files[0].size > 10495760) {
                toast.error("Kích thước file phải dưới 10mb.");
                return;
            }
            this.setState({
                loading: true
            });
            var bucketName = "ikara-data/chat";
            var extension = target.files[0].name.split('.').pop().toLowerCase();
            var keyName = this.guid() + "." + extension;
            var contentType = "image/jpeg";
            if (extension == "mp4") {
                contentType = "video/mp4";
            }
            if (extension == "mov") {
                contentType = "video/mov";
            }
            if (extension !== "jpeg" && extension !== "jpg" && extension !== "png" && extension !== "mp4" && extension !== "mov") {
                toast.warn("Không đúng định dạng")
                this.setState({
                    loading: false
                });
                return;
            }
            if ((extension === "mp4" || extension === "mov") && !this.acceptSendVideo()) {
                toast.warn("Không thể gửi video do chưa xác lập hội thoại")
                this.setState({
                    loading: false
                });
                return;
            }
            var fileReader = new FileReader();
            fileReader.onload = function () {
                this.setState({
                    loading: true,
                    message: 'Loading image...'
                });
                var fd = new FormData();
                fd.append('file', target.files[0]);
                fd.append('bucketName', bucketName);
                fd.append('objectKey', keyName);
                fd.append('contentType', contentType)
                $.ajax({
                    url: global.config.apiDomain + '/web.UploadFile ',
                    data: fd,
                    processData: false,
                    contentType: false,
                    type: 'POST',
                    success: function (data) {
                        this.setState({
                            loading: false,
                            message: '["https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName + '"]'
                        });
                        if (extension === "mp4") {
                            this.sendMessage("video");
                        } else {
                            this.sendMessage("image");
                        }
                    }.bind(this), error: function (jqXHR, exception) {
                        toast.error(exception);
                        this.setState({
                            loading: false
                        });
                    }.bind(this),
                });
            }.bind(this);
            fileReader.readAsArrayBuffer(target.files[0]);
        }
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    getMessageDetail() {
        const { user, targetUser } = this.state;
        if (!targetUser) return; // Thêm kiểm tra này để đảm bảo targetUser tồn tại

        var roomId = "";
        if (user.facebookId > targetUser.fromUserId) {
            roomId = targetUser.fromUserId + "-" + user.facebookId;
        } else {
            roomId = user.facebookId + "-" + targetUser.fromUserId;
        }
        this.setState({ roomId }, () => {
            this.ikaraNode
                .child("privateChats")
                .child(roomId)
                .orderByKey()
                .limitToLast(100)
                .get()
                .then(snapshot => {
                    var messageDetails = [];
                    snapshot.forEach(value => {
                        var chatItem = value.val();
                        chatItem.key = value.key;
                        if (chatItem.key != "pushNotification"
                            && chatItem.key != "readLimit"
                            && chatItem.key != "properties") {
                            if (chatItem.type != null && !(chatItem.type == "text" || chatItem.type == "image")) {
                                chatItem.originalMessage = "Chưa hỗ trợ xem data";
                            }
                            messageDetails.push(chatItem);
                        }
                    })
                    let cursorMessageDetails = messageDetails.length < 9 ? "END" : messageDetails[0].key;
                    this.setState({
                        messageDetails: messageDetails,
                        cursorMessageDetails: cursorMessageDetails,
                        loaded: true
                    }, () => {
                        this.scrollToBottom();
                        this.listenFirebaseMessgeDetails();
                    });
                });
        });
    }

    loadMoreMessageDetails() {
        const { roomId } = this.state;
        let { cursorMessageDetails } = this.state;
        if (cursorMessageDetails == "END") return;
        this.ikaraNode
            .child("privateChats")
            .child(roomId)
            .orderByKey()
            .endAt(cursorMessageDetails)
            .limitToLast(10)
            .get()
            .then(snapshot => {
                var messageDetails = [];
                snapshot.forEach(value => {
                    var chatItem = value.val();
                    chatItem.key = value.key;
                    if (!this.state.messageDetails.some(o => o.key == chatItem.key)) {
                        messageDetails.push(chatItem);
                    }
                })
                if (messageDetails.length < 9) {
                    this.state.cursorMessageDetails = "END";
                } else if (messageDetails.length > 0) {
                    this.state.cursorMessageDetails = messageDetails[0].key;
                }
                this.setState({
                    messageDetails: messageDetails.concat(this.state.messageDetails),
                });
                this.state.loading = false;
            });
    }

    isWithin30Days(timestamp) {
        const currentTime = Date.now();
        const timeDifference = currentTime - timestamp;
        const days30 = 30 * 24 * 60 * 60 * 1000;
        return timeDifference <= days30;
    }


    listenFirebaseMessages() {
        const { user } = this.state;
        const listenFirebaseMessages = this.ikaraNode
            .child("users")
            .child(user.facebookId)
            .child("privateChats");
        this.setState({ listenFirebaseMessages });

        listenFirebaseMessages.on('value', snapshot => {
            let messagesSnapshot = [];
            snapshot.forEach(value => {
                const message = value.val();
                message.fromUserId = value.key;
                const isWithin30Days = this.isWithin30Days(message.dateTime);
                if ( isWithin30Days && (!message.lastUserId.includes("KAKA") || message.isShowInAdminPage)) {
                    messagesSnapshot.push(message);
                }
            });
            messagesSnapshot = lodash.orderBy(messagesSnapshot, "dateTime", 'desc').slice(0, 100);
            this.setState({ messages: messagesSnapshot, loaded: true}, () => {
                console.log(this.state.messages)
                this.scrollToBottom();
                // this.runForLoop(messages);
            });
        });
    }

    runForLoop(messages) {
        let currentIndex = 0;
        const executeLoop = () => {
            if (this.state.isUserActive) {
                return;
            }

            if (currentIndex >= messages.length) {
                return;
            }

            this.setState({ targetUser: messages[currentIndex] }, () => {
                if (currentIndex >= 5) {
                    this.scrollToBottom1();
                }
                // if (!this.state.targetUser.isRead) {
                //     this.changeIsReadConversation(this.state.targetUser);
                // }
                this.getMessageDetail();
                // Do not increment currentIndex or call executeLoop again
            });
        };

        if (this.currentTimeout) {
            clearTimeout(this.currentTimeout);
        }
        executeLoop();
    }

    // runForLoop(messages) {
    //     let currentIndex = 0;
    //     const executeLoop = () => {
    //         if (this.state.isUserActive) {
    //             this.currentTimeout = setTimeout(executeLoop, 5000);
    //             return;
    //         }

    //         if (currentIndex >= messages.length) {
    //             return;
    //         }

    //         this.setState({ targetUser: messages[currentIndex] }, () => {
    //             if (currentIndex >= 5) {
    //                 this.scrollToBottom1();
    //             }
    //             if (!this.state.targetUser.isRead) {
    //                 this.changeIsReadConversation(this.state.targetUser);
    //             }
    //             this.getMessageDetail();
    //             currentIndex++;
    //             this.currentTimeout = setTimeout(executeLoop, 20000);
    //         });
    //     };

    //     if (this.currentTimeout) {
    //         clearTimeout(this.currentTimeout);
    //     }
    //     executeLoop();
    // }

    handleInputChange = (event) => {
        const value = event.target.value;
        this.setState({
            message: value,
            isUserActive: value.length > 0
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.sendMessage("text");
        }
    }

    offListen() {
        const { listenFirebaseMessages, listenFirebaseMessgeDetails } = this.state;
        // listenFirebaseMessgeDetails.off();
    }

    listenFirebaseMessgeDetails() {
        const { user, targetUser } = this.state;
        let { listenFirebaseMessgeDetails } = this.state;
        var roomId = "";
        if (user.facebookId > targetUser.fromUserId) {
            roomId = targetUser.fromUserId + "-" + user.facebookId;
        } else {
            roomId = user.facebookId + "-" + targetUser.fromUserId;
        }
        this.setState({ roomId });

        listenFirebaseMessgeDetails = this.ikaraNode
            .child("privateChats")
            .child(roomId)
            .orderByChild("dateTime")
            .limitToLast(1);

        listenFirebaseMessgeDetails.on('child_added', snapshot => {
            var chatItem = snapshot.val();
            chatItem.key = chatItem.dateTime;

            this.setState(prevState => {
                const updatedMessageDetails = prevState.messageDetails.map(msg => {
                    // Nếu tìm thấy tin nhắn local tương ứng, thay thế nó
                    if (msg.isLocalOnly && msg.message === chatItem.message && msg.type === chatItem.type) {
                        return { ...chatItem, isLocalOnly: false };
                    }
                    return msg;
                });

                // Nếu không tìm thấy tin nhắn local tương ứng, thêm mới
                if (!updatedMessageDetails.some(msg => msg.key === chatItem.key || msg.firebaseId === chatItem.firebaseId)) {
                    updatedMessageDetails.push(chatItem);
                }

                // Sắp xếp lại tin nhắn theo thời gian
                updatedMessageDetails.sort((a, b) => a.dateTime - b.dateTime);

                return { messageDetails: updatedMessageDetails };
            }, () => {
                this.scrollToBottom();
            });
        });

        this.setState({ listenFirebaseMessgeDetails });
    }

    handleTargetUser(targetUser) {
        const oldTargetUser = this.state.targetUser;
        if (oldTargetUser != null && targetUser.fromUserId == oldTargetUser.fromUserId) {
            return;
        }

        this.setState({
            targetUser: targetUser,
            searchKeyword: "",
            searchUsers: [],
            messageDetails: [] // Reset messageDetails khi chọn user mới
        }, () => {
            // if (!targetUser.isRead) {
            //     this.changeIsReadConversation(targetUser);
            // }
            if (oldTargetUser != null) {
                this.offListen();
            }
            this.getMessageDetail();
        });

        if (!this.state.messages.some(o => o.fromUserId == targetUser.fromUserId)) {
            this.setState(prevState => ({
                messages: [targetUser, ...prevState.messages]
            }));
        }
    }

    changeIsReadConversation(targetUser) {
        const json = {
            toFacebookId: targetUser.fromUserId
        }
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        const parameters = {
            "parameters": jsonBase64
        }
        const request = this.functions.httpsCallable("v5-ChangeIsReadConversation");
        request(parameters)
            .then(result => {
            }).catch(error => {
                alert(error);
            });
    }

    sendMessage = (type) => {
        const { user, targetUser, message} = this.state;
        if (message == "" || user == null || targetUser == null) {
            return;
        }
        const fromUser = {
            facebookId: user.facebookId,
            name: user.name,
            profileImageLink: user.profileImageLink
        }
        const toUser = {
            facebookId: targetUser.fromUserId,
            name: targetUser.userName,
            profileImageLink: targetUser.userProfile
        }
        const json = {
            fromUser: fromUser,
            toUser: toUser,
            message: message,
            type: type,
            firebaseId: this.ikaraNode.push(json).key
        }        
        const jsonBase64 = Base64.encode(JSON.stringify(json))
        var parameters = {
            "parameters": jsonBase64
        }
        const addMessage = this.functions.httpsCallable("v10-SendMessage");
        addMessage(parameters).then(result => {
        this.setState(prevState => ({
            message: "",
            isUserActive: false,
            messageDetails: [...prevState.messageDetails,json]
        }), () => {
            // this.runForLoop(this.state.messages);
            this.scrollToBottom();
        });
        }).catch(error => {
        })
    }

    scrollToBottom = () => {
        if (this.chatContainerRef.current) {
            this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
        }
    }

    scrollToBottom1 = () => {
        if (this.messagesBoxRef.current) {
            const currentScrollTop = this.messagesBoxRef.current.scrollTop;
            const newScrollTop = currentScrollTop + 200;
            const maxScrollTop = this.messagesBoxRef.current.scrollHeight - this.messagesBoxRef.current.clientHeight;
            this.messagesBoxRef.current.scrollTop = Math.min(newScrollTop, maxScrollTop);
        }
    }

    scrollToTop = () => {
        if (this.messagesBoxRef.current) {
            this.messagesBoxRef.current.scrollTop = 0;
        }
    }

    render() {
        const { user, targetUser, messages, messageDetails, searchUsers, loaded, message, searchKeyword } = this.state;

        return (
            <div className="list-chat-bot-user">
                <div className="friends-list-bot">
                    <div style={{ height: "590px", overflowY: "auto" }} className="messages-box" ref={this.messagesBoxRef}>
                        {loaded && (searchUsers.length === 0 || searchKeyword === "") ? (
                            <MessageList
                                messages={messages}
                                user={user}
                                targetUser={targetUser || {}}
                                handleTargetUser={this.handleTargetUser}
                            />
                        ) : (
                            <MessageList
                                messages={searchUsers}
                                user={user}
                                targetUser={targetUser}
                                handleTargetUser={this.handleTargetUser}
                            />
                        )}
                    </div>
                </div>
                <div className="chat-section">
                    {targetUser ? (
                        <>
                            <div className="form-control rounded-0 border-0 py-4 bg-light" style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="chat-header-bot">
                                    {targetUser.userName}
                                </div>
                            </div>
                            <div className="chat-container" ref={this.chatContainerRef}>
                                <ReactScroll
                                    className="mt-2 px-4 chat-box bg-white">
                                    <MessageDetailList
                                        messageDetails={messageDetails}
                                        user={user}
                                        targetUser={targetUser}
                                    />
                                </ReactScroll>
                            </div>
                            <div className="input-container-bot d-flex">
                                <input
                                    onKeyPress={this.handleKeyPress}
                                    value={message}
                                    onChange={this.handleInputChange}
                                    type="text"
                                    placeholder="Nhập tin nhắn và nhấn Enter để gửi..."
                                    aria-describedby="button-bot"
                                    className="form-control rounded-0 border-0 py-4 bg-light"
                                />
                                <div className="input-group-append d-flex align-items-center justify-content-center col-2">
                                    <label htmlFor="imageBotReply" className="btn input-group-text btn-outline-primary border-1">
                                        <i className="fa-solid fa-image icon-circle"></i>
                                    </label>
                                    <input
                                        type="file"
                                        id="imageBotReply"
                                        className="custom-file-input"
                                        onChange={this.uploadImage}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="no-user-selected">
                            Vui lòng chọn một người dùng để bắt đầu cuộc trò chuyện
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

MessagePage.contextType = MyContext;