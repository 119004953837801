import React, { Component } from 'react';
import axios from 'axios';
import RoomListModal from './components/RoomListModal'; // Import the new modal component
import { toast } from 'react-toastify';
import './components/partner.css';
import ModalRoomChair from './components/ModalRoomChair';
import ModalOnline from './components/ModalOnline';
import ModalIcoin from './components/ModalIcoin';
import ModalActive from './components/ModalActive';

export default class PartnerManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partners: [],
            partner: null,
            uid: null,
            roomUid: null,
            loading: false,
            showRoomListModal: false, // New state to control RoomListModal visibility
        };

        this.getAllPartner = this.getAllPartner.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        this.getAllPartner();
    }

    getAllPartner() {
        this.setState({ loading: true });
        axios.post(global.config.apiDomain + '/rest/partner-duration/web/get-partner-list', JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                partners: res.data.data.list,
                loading: false
            });
        }).catch(error => {
            console.error('Error during search:', error);
            this.setState({ loading: false });
        });
    }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }

    openRoomListModal(partner) {
        this.setState({
            partner: partner,
            showRoomListModal: true
        });
    }

    closeRoomListModal = () => {
        this.setState({
            showRoomListModal: false
        });
    };

    render() {
        const { partner, loading, showRoomListModal } = this.state;

        return (
            <>
                <RoomListModal
                    show={showRoomListModal}
                    onHide={this.closeRoomListModal}
                    roomList={partner ? partner.roomList : []}
                />

                <ModalActive uid={this.state.selectedUid} showModalActive={this.state.showModalActive} />
                <ModalRoomChair uid={this.state.selectedUid} showModalOnChair={this.state.showModalOnChair}  />
                <ModalOnline uid={this.state.selectedUid} showModalOnline={this.state.showModalOnline} />
                <ModalIcoin uid={this.state.selectedUid} showModalIcoin={this.state.showModalIcoin} />

                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Cộng Tác Viên</h1>
                        {loading ? (
                            <div className="text-center">
                                <i className="fa fa-spinner fa-spin fa-2x"></i>
                                <p>Đang tải...</p>
                            </div>
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">STT</th>
                                        <th scope="col">Avatar</th>
                                        <th scope="col">Tên</th>
                                        <th scope="col">UID</th>
                                        <th scope="col">Phòng live đang quản lý</th>
                                        <th scope="col">Chỉ số</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.partners.map((partner, index) => (
                                        <tr key={partner.id}>
                                            <td className="align-middle">
                                                {index + 1}
                                            </td>
                                            <td className="align-middle">
                                                <img src={partner.collaborator.profileImageLink} alt="Thumbnail" width="100" height="100" />
                                            </td>
                                            <td className="align-middle">
                                                {partner.collaborator.name}
                                            </td>
                                            <td className="align-middle">
                                                {partner.collaborator.uid}
                                            </td>
                                            <td className="align-middle" style={{width: '330px'}}>
                                                <button
                                                    onClick={() => this.openRoomListModal(partner)}
                                                    className="btn btn-secondary"
                                                >
                                                    Xem danh sách phòng
                                                </button>
                                            </td>
                                            <td className="align-middle" style={{width: '730px'}}>
                                                <button 
                                                    className="btn btn-danger"
                                                    data-toggle="modal" 
                                                    data-target="#modalRoomChair" 
                                                    onClick={() => this.setState({ selectedUid: partner.collaborator.uid, showModalOnChair: true })}
                                                >
                                                    Lên ghế
                                                </button>

                                                <button 
                                                    className="btn btn-success"
                                                    data-toggle="modal" 
                                                    data-target="#modalOnline" 
                                                    onClick={() => this.setState({ selectedUid: partner.collaborator.uid, showModalOnline: true })}
                                                >
                                                    User online
                                                </button>
                                                
                                                <button 
                                                    className="btn btn-warning text-white"
                                                    data-toggle="modal" 
                                                    data-target="#modalIcoin" 
                                                    onClick={() => {
                                                        this.setState({ selectedUid: partner.collaborator.uid, showModalIcoin: true });
                                                    }}
                                                >
                                                    Icoin
                                                </button>

                                                <button 
                                                    className="btn btn-primary text-white"
                                                    data-toggle="modal" 
                                                    data-target="#modalActive" 
                                                    onClick={() => {
                                                        this.setState({ selectedUid: partner.collaborator.uid, showModalActive: true });
                                                    }}
                                                >
                                                    CTV online
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </>
        );
    }
}