import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import getImageSize from 'image-size-from-url';


import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

export default class ModalUpdateGift extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gift: null,
            loading: false,
            register: true
        }

        this.saveGift = this.saveGift.bind(this);
        this.uploadThumbnailToS3 = this.uploadThumbnailToS3.bind(this);
        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);

        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
        this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    }

    componentWillReceiveProps(props) {
        // $("#show-animation").children().replaceWith("<div></div>");
        let { gift } = props;

        if (gift != null) {

            // if (gift.startDate != null) {
            //     gift.startDate = new Date(gift.startDate).toISOString().substr(0, 10);
            // }
            // if (gift.endDate != null) {
            //     gift.endDate = new Date(gift.endDate).toISOString().substr(0, 10);
            // }

            if (gift.id == "") {
                this.setState({
                    gift: gift,
                    register: true
                });
            } else {
                this.setState({
                    gift: gift,
                    register: false
                });

                if (gift.animatedUrl != null && gift.animatedUrl.endsWith("svga")) {
                    $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas2\"></div>");
                    this.loadSVGA2(gift.animatedUrl);
                } else if (gift.animatedUrl != null) {
                    $("#show-animation").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.animatedUrl + "\"  />");
                }

                if (gift.url != null && gift.url.endsWith("svga")) {
                    $("#show-image").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas\"></div>");
                    this.loadSVGA1(gift.url);
                } else if (gift.url != null) {
                    $("#show-image").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + gift.url + "\"  />");
                }
            }
        }
    }

    loadSVGA2(url) {
        var player = new SVGA.Player('#demoCanvas2');
        var parser = new SVGA.Parser('#demoCanvas2');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    loadSVGA1(url) {
        var player = new SVGA.Player('#demoCanvas');
        var parser = new SVGA.Parser('#demoCanvas');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    loadSVGA3(url) {
        var player = new SVGA.Player('#demoCanvas1');
        var parser = new SVGA.Parser('#demoCanvas1');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadImageToS3(event) {
        var target = event.target;

                 // Check if the file size exceeds 1MB
    if (target.files[0].size > 1048576) { // 1MB in bytes
        toast.warning("Hình ảnh không được vượt quá dung lượng 1Mb.");
        return;
    }

        //10495760
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var gift = this.state.gift;
        var bucketName = "ikara-data/images/gifts";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    gift.url = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.gift.url = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        gift: gift
                    });

                    if (gift != null && gift.url != null && (gift.url.endsWith(".svga") || gift.url.endsWith(".svg"))) {
                        $("#show-image").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas\"></div>");
                        this.loadSVGA1(gift.url);
                    } else {
                        $("#show-image").children().replaceWith(`<img style=\"height: 300px !important\"  src=${gift.url} />`);
                    }
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadAnimationToS3(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var gift = this.state.gift;
        var bucketName = "ikara-data/images/gifts";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    gift.animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.gift.animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        gift: gift
                    });

                    $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas1\"></div>");
                    this.loadSVGA3(gift.animatedUrl);
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadThumbnailToS3(event) {
        var target = event.target;
        if (target.files[0].size > 512999) {
            toast.error("Kích thước file phải dưới 500kb.");
            return;
        }

        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);

        fileReader1.onload = (fe) => {
            const image = new Image();
            image.src = fe.currentTarget.result;
            image.onload = function (ie) {
                let width = 0, height = 0;
                width = ie.currentTarget.naturalWidth;
                height = ie.currentTarget.naturalHeight;
                if (width != height || height > 256) {
                    toast.error("Kích thước ảnh tối đa 256px và Width = Height");
                    return;
                }

                this.setState({
                    loading: true
                });

                var gift = this.state.gift;
                var bucketName = "ikara-data/images/gifts";
                var extension = target.files[0].name.split('.').pop().toLowerCase();
                var keyName = this.guid() + "." + extension;
                var contentType = "image/jpeg";
                if (extension == "png") contentType = "image/png";

                var fileReader = new FileReader();

                fileReader.onload = function () {
                    var fd = new FormData();
                    fd.append('file', target.files[0]);
                    fd.append('bucketName', bucketName);
                    fd.append('objectKey', keyName);
                    fd.append('contentType', contentType)
                    $.ajax({
                        url: global.config.apiDomain + '/web.UploadFile',
                        data: fd,
                        processData: false,
                        contentType: false,
                        type: 'POST',
                        success: function (data) {
                            console.log(data)
                            this.setState({
                                loading: false
                            });

                            gift.url = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.setState({
                                gift: gift
                            });

                        }.bind(this),
                    });

                }.bind(this);
                fileReader.readAsArrayBuffer(target.files[0]);
            }.bind(this);
        }
    }

    saveGift() {
        const { gift, register } = this.state;

        this.setState({
            loading: true
        });

        if (register) {
            axios.post(global.config.apiDomain + '/rest/gifts/create', JSON.stringify(gift), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateGift").modal("hide");
                    this.props.resetList(res.data.gift);
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                console.error("Error occurred:", error);
                toast.error("Lỗi server, xử lý lại nha");
            });
        } else {
            axios.post(global.config.apiDomain + '/rest/gifts/save', JSON.stringify(gift), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateGift").modal("hide");
                    this.props.resetList(res.data.gift);
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                console.error("Error occurred:", error);
                toast.error("Lỗi server, xử lý lại nha");
            });
        }
    }

    updateStartDate(props) {
        let { gift } = this.state;
        let currentTime = props.value
        if (gift) {
            gift.startDate = currentTime;
            this.setState({
                gift: gift
            });
        }

    }

    updateEndDate(props) {
        let { gift } = this.state;
        let currentTime = props.value
        if (gift) {
            gift.endDate = currentTime;
            this.setState({
                gift: gift
            });
        }

    }

    render() {
        const model = bindModel(this);
        const { gift } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateGift" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Gift</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {true ? (
                                <div className="modal-body">
                                    <div id="id" className="row mb-2">
                                        <div className="col-3">
                                            <label>ID</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="platform"
                                                readOnly={this.state.register ? false : true}
                                                placeholder="Nhập ID..."
                                                {...model('gift.id')} />
                                        </div>
                                    </div>
                                    <div id="title" className="row mb-2">
                                        <div className="col-3">
                                            <label>Tên</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="title"
                                                placeholder="Nhập tên quà tặng..."
                                                {...model('gift.name')} />
                                        </div>
                                    </div>
                                    <div id="url" className="row mb-2">
                                        <div className="col-3">
                                            <label>Image</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile03"
                                                onChange={this.uploadImageToS3}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-image" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>

                                    <div id="animation" className="row mb-2">
                                        <div className="col-3">
                                            <label>Animated</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile03"
                                                onChange={this.uploadAnimationToS3}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>


                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Buy Price</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.buyPrice"
                                                placeholder="Nhập giá bán..."
                                                {...model('gift.buyPrice')} />
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Sell Price</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.sellPrice"
                                                placeholder="Nhập giá mua..."
                                                {...model('gift.sellPrice')} />
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Order</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.sellPrice"
                                                placeholder="Nhập giá mua..."
                                                {...model('gift.order')} />
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Ngày mở bán</label>
                                        </div>
                                        <div className="col-9">
                                            {/* <input
                                                className="form-control"
                                                type="date"
                                                name="gift.startDate"
                                                placeholder="Chọn ngày mở bán..."
                                                {...model('gift.startDate')} /> */}
                                            <DateTimePickerComponent
                                                change={this.updateStartDate}
                                                value={gift ? new Date(gift.startDate) : new Date()}
                                            >
                                            </DateTimePickerComponent>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-3">
                                        </div> 
                                        <div className="col-9">
                                            <h7 className='text-danger'>Nhấn X để xóa Ngày kết thúc nếu muốn set VÔ HẠN</h7>
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Ngày kết thúc</label>
                                        </div>
                                        <div className="col-9">
                                            {/* <input
                                                className="form-control"
                                                type="date"
                                                name="gift.endDate"
                                                placeholder="Chọn ngày kết thúc..."
                                                {...model('gift.endDate')} /> */}
                                            <DateTimePickerComponent
                                                change={this.updateEndDate}
                                                value={gift ? new Date(gift.endDate) : null}
                                            >
                                            </DateTimePickerComponent>
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Min level</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.minLevel"
                                                {...model('gift.minLevel')} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-3">
                                        </div> 
                                        <div className="col-9">
                                            <h7 className='text-danger'>KHÔNG nhập số nếu level VÔ HẠN</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Max level</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.maxLevel"
                                                {...model('gift.maxLevel')} />
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Min vip level</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.minVipLevel"
                                                {...model('gift.minVipLevel')} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-3">
                                        </div> 
                                        <div className="col-9">
                                            <h7 className='text-danger'>KHÔNG nhập số nếu level VÔ HẠN</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Max vip level</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.maxVipLevel"
                                                {...model('gift.maxVipLevel')} />
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Min family level</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.minVipLevel"
                                                {...model('gift.minFamilyLevel')} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-3">
                                        </div> 
                                        <div className="col-9">
                                            <h7 className='text-danger'>KHÔNG nhập số nếu level VÔ HẠN</h7>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Max family level</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="gift.maxVipLevel"
                                                {...model('gift.maxFamilyLevel')} />
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Type</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                {...model('gift.type')} >
                                                <option value="">Chọn thể loại</option>
                                                <option value="ANIMATED">ANIMATED</option>
                                                <option value="STATIC">STATIC</option>
                                                <option value="COMBO">COMBO</option>
                                                <option value="LUCKYBOX">LUCKYBOX</option>
                                                <option value="DRAWABLE">DRAWABLE</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Show</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="gift.show"
                                                {...model('gift.show')} >
                                                <option value="ALL">ALL</option>
                                                <option value="LIVEROOM">LIVEROOM</option>
                                                <option value="RECORDING">RECORDING</option>
                                                <option value="NONE">NONE</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Tag</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                {...model('gift.tag')} >
                                                <option value="">Chọn tag</option>
                                                <option value="NORMAL">NORMAL</option>
                                                <option value="EVENT">EVENT</option>
                                                <option value="VIP">VIP</option>
                                                <option value="SPECIAL">SPECIAL</option>
                                                <option value="FAMILY">FAMILY</option>
                                                <option value="USER">USER</option>
                                                <option value="COUPLE">COUPLE</option>
                                                {/* <option value="LUCKYGIFT">LUCKYGIFT</option> */}
                                            </select>
                                        </div>

                                    </div>

                                    {gift != null && gift.tag == "USER" && (
                                        <div id="link" className="row mb-2">
                                            <div className="col-3">
                                                <label>User ID</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="gift.userUID"
                                                    {...model('gift.userUID')} />
                                            </div>
                                        </div>
                                    )}

                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <textarea rows="4"
                                                className="form-control mb-2"
                                                placeholder="Nhập mô tả"
                                                {...model('gift.description')} />
                                        </div>
                                    </div>
                                </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveGift}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}